import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ShowAt, HideAt } from "react-with-breakpoints";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomInput from "../common/CustomInput";
import useContacto from "./contacto/hooks/useContacto";
import { Formik, Form } from "formik";

export default function Posventa() {
  /* const handleShowDecription = ()=>{

    }
    style={{  visibility : description}} */

  const { advanceSchema, onSubmit, numberRegex, justletterRegex } = useContacto(
    { tipoConsulta: "SERVICIOS" }
  );

  const screenSize = {
    MOBILE: {
      titleMarginTop: "0",
      position: "relative",
      titleFontSize: "28px",
      titleAlignment: "text-left",
    },
    DESKTOP: {
      titleMarginTop: "130px",
      descriptionMarginTop: "20px",
      position: "absolute",
      bottom: 0,
      titleFontSize: "3.5rem",
      titleAlignment: "text-center",
    },
  };

  const cardData = [
    {
      title: "Turnos online",
      description: `Solicitá tu turno al taller de la forma más rápida.`,
      action: "Comprar",
      url: "/toyota_app",
    },
    {
      title: "Accesorios",
      description: `Encontrá todos los accesorios para tu Toyota.`,
      action: "Comprar",
      url: "https://store.autoprana.com.ar/#/accesorios",
    },
    {
      title: "Repuestos",
      description: `Encontrá todos los accesorios para tu Toyota.`,
      action: "Comprar",
      url: "https://store.autoprana.com.ar/#/repuestos",
    },
    {
      title: "Contactanos",
      description: `Hace click para enviarnos un whatsapp o comunicate al 4505-8632.`,
      action: "Contactar",
      url: "https://wa.me/5491145058700?text=Hola,%20me%20estoy%20comunicando%20desde%20la%20web%20seccion%20posventa.%20Mi%20consulta%20es:%20",
    },
  ];

  const goToUrl = (url) => (window.location = url);

  const renderCards = (style) => (
    <Row
      className="m-0 p-4 cubeContainer"
      style={{
        backgroundImage: "url(images/homeNews/wallPosventa.jpg)",
      }}
    >
      <Col
        className={`${style.titleAlignment} mt-1`}
        xs={12}
        style={{ marginBottom: "0%", color: "white" }}
      >
        <span style={{ fontSize: style.titleFontSize, whiteSpace: "pre-line" }}>
          <b>
            Conocé los Servicios y Accesorios para tu {"\n"} Toyota en Prana
          </b>
        </span>
      </Col>

      <Col
        className={`${style.titleAlignment} mt-1`}
        xs={12}
        style={{ marginBottom: "15%", color: "white" }}
      >
        <Row className="m-0 p-0 mt-5">
          <Col xs={12} xl={{ span: "8", offset: "2" }} className="mb-5 mt-3">
            {/* <Row
              className="m-0 p-0"
              style={{ borderRadius: "5px", backgroundColor: "lightGray" }}
            >
              <Col
                xs={12}
                className="text-center pb-1"
                style={{
                  backgroundColor: "darkgray",
                  fontSize: "1.5rem",
                  borderRadius: "5px",
                  color: "black",
                }}
              >
                Posventa
              </Col>
            </Row> */}

            <Formik
              initialValues={{
                nombre: "",
                email: "",
                telefono: "",
                mensaje: "",
              }}
              validationSchema={advanceSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, values, setFieldValue }) => (
                <Form autoComplete="off">
                  <Row className="m-0 p-0 mt-3">
                    <Col
                      xs={12}
                      lg={{ span: "8", offset: "2" }}
                      className="mb-4"
                    >
                      <CustomInput
                        label={
                          <span>
                            <FontAwesomeIcon icon="user" /> &nbsp; Nombre y
                            apellido
                          </span>
                        }
                        name="nombre"
                        type="text"
                        placeholder="Ingrese nombre y apellido"
                        value={values.nombre}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          if (
                            !value ||
                            justletterRegex.test(value.slice(-1).toString())
                          ) {
                            setFieldValue("nombre", value);
                          }
                        }}
                      />
                      <CustomInput
                        label={
                          <span>
                            <FontAwesomeIcon icon="phone" /> &nbsp; Telefono
                          </span>
                        }
                        name="telefono"
                        type="text"
                        placeholder="solo numeros"
                        value={values.telefono}
                        onChange={(e) => {
                          e.preventDefault();
                          const { value } = e.target;
                          if (!value || numberRegex.test(value.toString())) {
                            setFieldValue("telefono", value);
                          }
                        }}
                      />
                      <CustomInput
                        label={
                          <span>
                            <FontAwesomeIcon icon="at" /> &nbsp; Email
                          </span>
                        }
                        name="email"
                        type="text"
                        placeholder="cliente@email.com"
                        value={values.correo}
                      />
                      <CustomInput
                        label={
                          <span>
                            <FontAwesomeIcon icon="comment" /> &nbsp; Mensaje
                          </span>
                        }
                        name="mensaje"
                        type="text"
                        placeholder="ingrese su mensaje"
                        value={values.mensaje}
                        maxLength="300"
                      />
                    </Col>
                  </Row>
                  <Row className="m-0 p-0">
                    <Col xs={12} className="text-center">
                      <Button
                        type="submit"
                        className="btn btn-primary"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? "Procesando..." : "Enviar"}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Col>

      {cardData.map(({ title, description, action, url }) => (
        <Col
          xs={12}
          lg={3}
          style={{ cursor: "pointer" }}
          className="m-0 p-0"
          onClick={() => goToUrl(url)}
        >
          <div className="grow" style={style}>
            <h2
              className="text-white text-left pl-4"
              style={{ marginTop: style.titleMarginTop }}
            >
              {title}
            </h2>
            <p
              className="text-white text-left px-4 pb-4"
              style={{ marginTop: style.descriptionMarginTop }}
            >
              {description}
              <span className="card-link-action" style={{ display: "block" }}>
                {action}
              </span>
            </p>
          </div>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <ShowAt breakpoint="largeAndAbove">
        {renderCards(screenSize.DESKTOP)}
      </ShowAt>
      <HideAt breakpoint="largeAndAbove">
        {renderCards(screenSize.MOBILE)}
      </HideAt>
    </>
  );
}
