import React from "react";

import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

export default function CardFeatures({ slide, i, handleVersionSeleccionada }) {
  let versionSeleccionada = slide;

  return (
    <Card
      className="cardShadow my-4 cardCustombackground"
      key={i}
      border="black"
      style={{
        borderRadius: "7px",
        height: "auto",
      }}
    >
      <Card.Body>
        <Card.Title className="mb-5">{slide && slide.version}</Card.Title>

        <Card.Text>
          {slide &&
            slide.datos.map((datos, i) => {
              return (
                <span key={i}>
                  {datos.caracteristica}
                  <b>{datos.descripcion}</b>
                  <hr></hr>
                </span>
              );
            })}
        </Card.Text>
        <Col className="m-0 p-0">
          <Button
            style={{ borderRadius: "15px", fontSize: "13px" }}
            variant="outline-dark"
            onClick={() => handleVersionSeleccionada(versionSeleccionada)}
          >
            <b>Seleccionar</b>
          </Button>
        </Col>
      </Card.Body>
    </Card>
  );
}
