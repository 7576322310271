import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Image from "react-bootstrap/Image";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Modal from "react-bootstrap/Modal";
import Slider from "../common/Slider";

import { ShowAt, HideAt } from "react-with-breakpoints";
import { Link } from "react-router-dom";

import { modelo } from "../data/ModelosData";

export default function ModeloSeleccionado({ modeloSeleccionado, posicion }) {
  console.log(posicion, "posicion");

  useEffect(() => {
    setTipoSeleccionado(modelo[posicion].tipos[0]);
    setModeloInicial(modelo[posicion].tipos[0].imagenBlur);
    setImagenDisplay(modelo[posicion].tipos[0].imagenBlur);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posicion]);

  const [tipoSeleccionado, setTipoSeleccionado] = useState(
    modelo[posicion].tipos[0]
  );

  const [versionSeleccionada, setVersionSeleccionada] = useState();

  const [modeloInicial, setModeloInicial] = useState(
    modelo[posicion].tipos[0].imagenBlur
  );

  const [imagenDisplay, setImagenDisplay] = useState(modeloInicial);

  const [show, setShow] = useState(false);

  const [imgIntExt, setImgIntExt] = useState(null);

  function handleShow() {
    setShow(true);
  }

  const handleVersionSeleccionada = (version) => {
    setVersionSeleccionada(version);
    setImagenDisplay(version.imagenModelo);
  };

  const showColores = () => {
    if (imagenDisplay !== modeloInicial && versionSeleccionada) {
      let colores = versionSeleccionada.colores.map((detalle, i) => {
        return (
          <>
            <Col
              style={{ paddingBottom: "5px" }}
              xs={3}
              id="wrapper"
              onClick={() => setImagenDisplay(detalle.imagen)}
              key={i}
            >
              <Col className="hover">
                <div
                  className="circleDivs"
                  style={{
                    backgroundColor: detalle.color,
                  }}
                ></div>
              </Col>
              <div className="text">{detalle.nombre}</div>
            </Col>
          </>
        );
      });
      return colores;
    }
  };

  const handleDataTipoElegido = (tipo, modeloInicial) => {
    setTipoSeleccionado(tipo);
    setModeloInicial(modeloInicial);
    setImagenDisplay(modeloInicial);
  };

  const showTipos = () => {
    // eslint-disable-next-line array-callback-return
    let tipo = modelo.map((detalle, i) => {
      if (modeloSeleccionado === detalle.nombre) {
        return (
          <>
            <Col key={i} lg={{ span: 8, offset: 2 }}>
              <Row className="m-0 p-0 justify-content-center">
                {detalle.tipos.map((tipo, i) => (
                  /* console.log(tipo.tipo, i), */
                  <Col
                    onClick={() =>
                      handleDataTipoElegido(
                        modelo[posicion].tipos[i],
                        modelo[posicion].tipos[i].imagenBlur
                      )
                    } //ver de armar fn para armar url del (ej yaris-sedan)
                    key={i}
                    className="m-0 p-0 text-center mb-1 showButton"
                    md={4}
                  >
                    <h3 className="mt-1" style={{ cursor: "pointer" }}>
                      <b>{tipo.tipo}</b>
                    </h3>

                    <Image
                      className="mb-3 mt-3"
                      style={
                        tipo.tipo !== tipoSeleccionado.tipo
                          ? { cursor: "pointer", opacity: "0.5" }
                          : { cursor: "pointer" }
                      }
                      width="220px"
                      src={tipo.imagen}
                    />
                  </Col>
                ))}
              </Row>
            </Col>
          </>
        );
      }
    });
    return tipo;
  };

  return (
    <>
      <Row className="m-0 p-0 pt-2">
        <Col className="m-0 pl-3 p-0">
          <Breadcrumb style={{ paddingTop: "0" }}>
            <Link to="/">
              <Breadcrumb.Item href="www.autoprana.com.ar/">
                &nbsp;Home /&nbsp;
              </Breadcrumb.Item>
            </Link>
            <Link to="/modelos">
              <Breadcrumb.Item href="www.autoprana.com.ar/modelos">
                Modelos /&nbsp;
              </Breadcrumb.Item>
            </Link>
            <Breadcrumb.Item active>{modeloSeleccionado}</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
        <a href="https://wa.me/5491145058700?text=Hola,%20me%20estoy%20comunicando%20desde%20la%20web.%20Mi%20consulta%20es:%20">
          <Col className="m-0 p-0 text-right pr-3">
            <Button size="sm" variant="dark">
              Contactanos
            </Button>
          </Col>
        </a>
      </Row>
      {showTipos()}
      <Row className="m-0 p-0 justify-content-md-center">
        <Col className="m-0 p-0" xs={12}>
          <Image className="cropped" src={tipoSeleccionado.fondo} />
        </Col>
      </Row>
      <Row className="mx-0 text-center" style={{ marginTop: "80px" }}>
        <Col className="m-0 p-0">
          <h2>
            <b>{tipoSeleccionado.sloganStore}</b>
          </h2>
          <h5 className="mt-5">{tipoSeleccionado.bajadaStore}</h5>
          <Row className="m-0 mt-5 p-0">
            <Col className="m-0 my-3 p-0">
              <a href="https://store.autoprana.com.ar/vtf/" target="_self">
                <Button style={{ borderRadius: "15px" }} variant="outline-dark">
                  <b>Cotiza Online</b>
                </Button>
              </a>
            </Col>
          </Row>
        </Col>

        <Col xs={12} md={6} className="m-0 mt-3 p-0">
          <ShowAt breakpoint="largeAndAbove">
            <Image
              style={{ borderRadius: "15px" }}
              src={tipoSeleccionado.imagenModeloStore}
              width="500px"
            ></Image>
          </ShowAt>
          <HideAt breakpoint="largeAndAbove">
            <Image
              style={{ borderRadius: "15px" }}
              src={tipoSeleccionado.imagenModeloStore}
              width="80%"
            ></Image>
          </HideAt>
        </Col>
      </Row>

      {/* vvvvvvvvvvv mobile breakpoint vvvvvvvvvvv */}
      <HideAt breakpoint="largeAndAbove">
        <Row className="m-0 p-0">
          <Col
            style={{
              marginTop: "100px",
              cursor: "pointer",
            }}
          >
            <h2>
              <b>Encontrá tu versión</b>
            </h2>
            <a href="https://store.autoprana.com.ar/vtf/" target="_self">
              <Image src="/images/logos/pesos.svg" />
              <span className="pl-3">Ver disponibilidad</span>
            </a>
          </Col>
          <Col
            lg={4}
            style={{
              marginTop: "5px",
              cursor: "pointer",
            }}
          >
            <Image
              src={
                imagenDisplay !== modeloInicial ? imagenDisplay : modeloInicial
              }
              style={
                imagenDisplay !== modeloInicial
                  ? { width: "100%" }
                  : { opacity: "0.5", filter: "blur(4px)", width: "100%" }
              }
            />
          </Col>
          <Col
            lg={3}
            style={{
              cursor: "pointer",
            }}
            className={"text-left mb-5"}
          >
            <Row>
              <Col xs={12}>
                <Row>{imagenDisplay !== modeloInicial && showColores()}</Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </HideAt>
      {/* ^^^^^^^^^^^^ mobile breakpoint ^^^^^^^^^^^^ */}

      <HideAt breakpoint="mediumAndBelow">
        <Row className="m-0 p-0">
          <Col
            md={{ span: 3, offset: 1 }}
            style={{
              marginTop: "100px",
              cursor: "pointer",
            }}
            className="text-right"
          >
            <h2>
              <b>Encontrá tu versión</b>
            </h2>
            <a href="https://store.autoprana.com.ar/vtf/" target="_self">
              <Image src="/images/logos/pesos.svg" />
              <span className="pl-3">Ver disponibilidad</span>
            </a>
          </Col>
          <Col
            md={4}
            style={{
              marginTop: "100px",
              cursor: "pointer",
            }}
          >
            <Image
              src={
                imagenDisplay !== modeloInicial ? imagenDisplay : modeloInicial
              }
              style={
                imagenDisplay !== modeloInicial
                  ? { width: "100%" }
                  : { opacity: "0.5", filter: "blur(4px)", width: "100%" }
              }
            />
          </Col>
          <Col
            md={3}
            style={{
              marginTop: "100px",
              cursor: "pointer",
            }}
            className={"text-left"}
          >
            <Row>
              <Col xs={12}>
                <Image
                  src={tipoSeleccionado.miniatura}
                  //cambiar por imagen lateral del auto pequeño
                  width="40%"
                  style={{ opacity: "0.5" }}
                />
              </Col>
              <Col
                className="mb-2"
                xs={12}
                style={{ fontSize: "12px", whiteSpace: "pre-line" }}
              >
                Colores{"\n"}
                Disponibles
              </Col>
              <Col xs={4}>
                <Row>{imagenDisplay !== modeloInicial && showColores()}</Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </HideAt>
      <Row style={{ margin: "0 0 -40px 0" }}>
        <Col
          xs={6}
          md={{ span: 2, offset: 2 }}
          style={{ borderBottom: "2px solid red" }}
        >
          <b style={{ fontSize: "16px" }}>Seleccioná tu versión</b>
        </Col>
        <Col xs={1} md={4} style={{ borderBottom: "2px solid gray" }}></Col>
      </Row>

      <Row className="m-0 mt-2 p-0">
        <Col md={{ span: 8, offset: 2 }}>
          <Slider
            images={tipoSeleccionado.versiones}
            objectToRender="cardFeatures"
            infiniteLoop={false}
            handleVersionSeleccionada={handleVersionSeleccionada}
          />
        </Col>
      </Row>

      <hr style={{ marginTop: "60px", marginBottom: "40px" }}></hr>

      <Row className="m-0 mb-5 p-0 text-center">
        <Col lg={{ span: 4, offset: 4 }}>
          <Row className="m-0 p-0">
            <Col className="mb-4">
              <Col>
                <a
                  href={tipoSeleccionado.catalogo}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src="/images/logos/arrow.svg" width="60px" />
                  {/* descargar SVG */}
                </a>
              </Col>
              <Col>Catalogo</Col>
            </Col>
            <Col>
              <Col>
                <a
                  href="https://www.tcfautos.com.ar/#/cotizador/?ic=307&utm_source=TASA&utm_medium=Web&utm_campaign=Tasa"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src="/images/logos/pesos.svg" width="60px" />
                  {/* descargar SVG */}
                </a>
              </Col>
              <Col>Financiación</Col>
            </Col>
            <Col>
              <Col>
                <a
                  href="https://store.autoprana.com.ar/#/kinto"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image
                    src="/images/logos/kinto-mobility-service.svg"
                    width="60px"
                  />
                  {/* descargar SVG */}
                </a>
              </Col>
              <Col>Probar con KINTO</Col>
            </Col>
            <Col>
              <Col>
                <a
                  href="https://wa.me/5491145058700?text=Hola,%20me%20estoy%20comunicando%20desde%20la%20web.%20Mi%20consulta%20es:%20"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src="/images/logos/information.svg" width="60px" />
                  {/* descargar SVG */}
                </a>
              </Col>
              <Col>Contactanos</Col>
            </Col>
          </Row>
        </Col>
      </Row>
      {tipoSeleccionado.tipo !== "GR Yaris" &&
        tipoSeleccionado.tipo !== "Hiace Commuter" &&
        tipoSeleccionado.tipo !== "Hiace Wagon" &&
        tipoSeleccionado.tipo !== "SW4 GR-Sport" &&
        tipoSeleccionado.tipo !== "Land Cruiser 300 GR-S" && (
          <Row className="p-0" style={{ margin: "80px 0 0 0" }}>
            <Col xs={12} md={{ span: 6, offset: 2 }} className="mb-5">
              <h2>
                <b>Información de consumo</b>
              </h2>
            </Col>
            <Col xs={12} md={3} className="text-center">
              <a
                href={tipoSeleccionado.consumo}
                target="_black"
                rel="noreferrer"
              >
                <Button style={{ borderRadius: "15px" }} variant="outline-dark">
                  <b style={{ padding: "35px" }}>Ver más</b>
                </Button>
              </a>
            </Col>
          </Row>
        )}
      <Row className="m-0 mt-5 p-0">
        <Col
          xs={12}
          md={{ span: 4, offset: 2 }}
          id="imageContainer"
          onClick={() => handleShow()}
          style={{
            borderRadius: "10px",
            height: "400px",
          }}
          className="p-0"
        >
          <Col
            xs={12}
            className="p-3"
            onClick={() => setImgIntExt(tipoSeleccionado.imagenesInterior)}
          >
            <Row className="justify-content-md-center m-0">
              <Col className="textContainer m-0 mb-5 p-0">
                <Image
                  id="image"
                  src={tipoSeleccionado.interior}
                  width="100%"
                  height="400px"
                  style={{
                    objectFit: "cover",
                    /* filter: "brightness(40%)", */
                    borderRadius: "10px",
                  }}
                  alt="imagen carousel"
                />
                <div class="textCentered">
                  Conocé el{" "}
                  <h2>
                    <b>Interior</b>
                  </h2>
                </div>
              </Col>
            </Row>
          </Col>
        </Col>

        <Col
          className="p-0"
          xs={12}
          md={4}
          id="imageContainer"
          onClick={() => handleShow()}
          style={{
            borderRadius: "10px",
            height: "400px",
          }}
        >
          <Col
            xs={12}
            className="p-3"
            onClick={() => setImgIntExt(tipoSeleccionado.imagenesExterior)}
          >
            <Row className="justify-content-md-center m-0">
              <Col className="textContainer m-0 p-0">
                <Image
                  id="image"
                  src={tipoSeleccionado.exterior}
                  width="100%"
                  height="400px"
                  style={{
                    objectFit: "cover",
                    /* filter: "brightness(40%)", */
                    borderRadius: "10px",
                  }}
                  alt="imagen carousel"
                />
                <div class="textCentered">
                  Conocé el{" "}
                  <h2>
                    <b>Exterior</b>
                  </h2>
                </div>
              </Col>
            </Row>
          </Col>
        </Col>
      </Row>
      <Row className="m-0 p-0 justify-content-md-center">
        <h1 style={{ marginTop: "80px", marginBottom: "80px" }}>
          <b>Tu primer Toyota, mejor que nunca</b>
        </h1>
      </Row>
      <Row className="m-0 p-0">
        <Image src={tipoSeleccionado.imagenSalida} width="100%" />
      </Row>
      <>
        <Modal id="modalSize" show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton style={{ height: "50px" }}>
            <Modal.Title>
              <Image
                style={{ marginTop: "-20px" }}
                src="https://store.autoprana.com.ar/img/imgSitio/logos/logoBig.png"
                width="125px"
              />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="m-0 p-0" style={{ backgroundColor: "black" }}>
            <Slider
              images={imgIntExt}
              objectToRender="interiorExterior"
              infiniteLoop={false}
            />
          </Modal.Body>
        </Modal>
      </>
    </>
  );
}
