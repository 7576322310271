import React, { useState } from "react";
export const uiContext = React.createContext();

export default function UiContextProvider({ children }) {
  const [sideBarCollapsed, setSideBarCollapsed] = useState(true);
  const [sideBarToggled, setSideBarToggled] = useState(false);

  return (
    <uiContext.Provider
      value={{
        sideBar: {
          collapsed: sideBarCollapsed,
          toggleCollapsed: () => {
            setSideBarCollapsed(!sideBarCollapsed);
          },

          toggled: sideBarToggled,
          hide: () => {
            setSideBarToggled(false);
            setSideBarCollapsed(!sideBarCollapsed);
          },
          show: () => {
            setSideBarToggled(true);
            setSideBarCollapsed(false);
          },
        },
      }}
    >
      {children}
    </uiContext.Provider>
  );
}
