export const modelos = [
  {
    nombre: "Etios",
    imagen: "/images/modelos/etios/etios.png",
    logo: "",
  },
  {
    nombre: "Etios Aibo",
    imagen: "/images/modelos/etiosAibo/etiosAibo.png",
    logo: "",
  },
  {
    nombre: "Yaris",
    imagen: "/images/modelos/yaris/yaris.svg",
    logo: "",
  },
  {
    nombre: "GR Yaris",
    imagen: "/images/modelos/yarisGr/yarisGr.png",
    logo: "/images/logos/grMini.png",
  },
  {
    nombre: "Corolla",
    imagen: "/images/modelos/corolla/corolla.png",
    logo: "/images/logos/hybridMini.png",
    titulo: "Autos y Minivans",
  },
  {
    nombre: "Corolla GR-Sport",
    imagen: "/images/modelos/corollaGr/corollaGr.png",
    logo: "/images/logos/grMini.png",
  },
  /*  {
    nombre: "Prius",
    imagen: "/images/modelos/prius/prius.png",
    logo: "/images/logos/hybridMini.png",
  }, */
  {
    nombre: "Camry",
    imagen: "/images/modelos/camry/camry.png",
    logo: "/images/logos/hybridMini.png",
  },
  {
    nombre: "Hilux",
    imagen: "/images/modelos/hilux/hilux.png",
    logo: "",
  },
  {
    nombre: "Hiace Furgón",
    imagen: "/images/modelos/hiaceFurgon/hiaceFurgon.png",
    logo: "",
  },
  {
    nombre: "Hiace Commuter",
    imagen: "/images/modelos/hiaceCommuter/hiaceCommuter.png",
    logo: "",
  },
  {
    nombre: "Hiace Wagon",
    imagen: "/images/modelos/hiaceWagon/hiaceWagon.png",
    logo: "",
  },
  {
    nombre: "C-HR",
    imagen: "/images/modelos/chr/chr.png",
    logo: "/images/logos/hybridMini.png",
  },
  {
    nombre: "Corolla Cross",
    imagen: "/images/modelos/corollaCross/corollaCross.png",
    logo: "/images/logos/hybridMini.png",
  },
  {
    nombre: "RAV4",
    imagen: "/images/modelos/rav4/rav4.png",
    logo: "/images/logos/hybridMini.png",
  },
  {
    nombre: "SW4",
    imagen: "/images/modelos/sw4/sw4.png",
    logo: "",
  },
  {
    nombre: "SW4 DIAMOND",
    imagen: "/images/modelos/sw4Diamond/sw4Diamond.png",
    logo: "",
  },
  {
    nombre: "SW4 GR-Sport",
    imagen: "/images/modelos/sw4Gr/sw4Gr.png",
    logo: "/images/logos/grMini.png",
  },
  {
    nombre: "Land Cruiser Prado",
    imagen: "/images/modelos/landCruiserPrado/landCruiserPrado.png",
    logo: "",
  },
  {
    nombre: "Land Cruiser 300 VX",
    imagen: "/images/modelos/landCruiser300VX/landCruiser300VX.png",
    logo: "",
  },
  {
    nombre: "Land Cruiser 300 GR-S",
    imagen: "/images/modelos/landCruiser300Gr/landCruiser300Gr.png",
    logo: "/images/logos/grMini.png",
  },
];

/* export const modelos = [
  {
    tipo: "Autos y Minivans",
    modelos: [
      {
        nombre: "Etios",
        imagen: "/images/modelos/etios/etios.png",
        logo: "",
      },
      {
        nombre: "Etios Aibo",
        imagen: "/images/modelos/etiosAibo/etiosAibo.png",
        logo: "",
      },
      {
        nombre: "Yaris",
        imagen: "/images/modelos/yaris/yaris.svg",
        logo: "",
      },
      {
        nombre: "GR Yaris",
        imagen: "/images/modelos/yarisGr/yarisGr.png",
        logo: "/images/logos/grMini.png",
      },
      {
        nombre: "Corolla",
        imagen: "/images/modelos/corolla/corolla.png",
        logo: "/images/logos/hybridMini.png",
        titulo: "Autos y Minivans",
      },
      {
        nombre: "Corolla GR-Sport",
        imagen: "/images/modelos/corollaGr/corollaGr.png",
        logo: "/images/logos/grMini.png",
      },
      {
        nombre: "Prius",
        imagen: "/images/modelos/prius/prius.png",
        logo: "/images/logos/hybridMini.png",
      },
      {
        nombre: "Camry",
        imagen: "/images/modelos/camry/camry.png",
        logo: "/images/logos/hybridMini.png",
      },
    ],
  },
  {
    tipo: "Pickups y Comerciales",
    modelos: [
      {
        nombre: "Hilux",
        imagen: "/images/modelos/hilux/hilux.png",
        logo: "",
      },
      {
        nombre: "Hiace Furgón",
        imagen: "/images/modelos/hiaceFurgon/hiaceFurgon.png",
        logo: "",
      },
      {
        nombre: "Hiace Commuter",
        imagen: "/images/modelos/hiaceCommuter/hiaceCommuter.png",
        logo: "",
      },
      {
        nombre: "Hiace Wagon",
        imagen: "/images/modelos/hiaceWagon/hiaceWagon.png",
        logo: "",
      },
    ],
  },
  {
    tipo: "SUV y CROSSOVERS",
    modelos: [
      {
        nombre: "C-HR",
        imagen: "/images/modelos/chr/chr.png",
        logo: "/images/logos/hybridMini.png",
      },
      {
        nombre: "Corolla Cross",
        imagen: "/images/modelos/corollaCross/corollaCross.png",
        logo: "/images/logos/hybridMini.png",
      },
      {
        nombre: "RAV4",
        imagen: "/images/modelos/rav4/rav4.png",
        logo: "/images/logos/hybridMini.png",
      },
      {
        nombre: "SW4",
        imagen: "/images/modelos/sw4/sw4.png",
        logo: "",
      },
      {
        nombre: "SW4 DIAMOND",
        imagen: "/images/modelos/sw4Diamond/sw4Diamond.png",
        logo: "",
      },
      {
        nombre: "SW4 GR-Sport",
        imagen: "/images/modelos/sw4Gr/sw4Gr.png",
        logo: "/images/logos/grMini.png",
      },
      {
        nombre: "Land Cruiser Prado",
        imagen: "/images/modelos/landCruiserPrado/landCruiserPrado.png",
        logo: "",
      },
      {
        nombre: "Land Cruiser 300 VX",
        imagen: "/images/modelos/landCruiser300VX/landCruiser300VX.png",
        logo: "",
      },
      {
        nombre: "Land Cruiser 300 GR-S",
        imagen: "/images/modelos/landCruiser300Gr/landCruiser300Gr.png",
        logo: "/images/logos/grMini.png",
      },
    ],
  },
]; */

export const modelo = [
  {
    nombre: "Etios",
    tipos: [
      {
        tipo: "Etios Hatchback",
        imagen: "/images/modelos/etios/etiosHatchback/etiosHatchback.png",
        imagenBlur: "/images/modelos/etios/etiosHatchback/blur.png",
        fondo: "/images/modelos/etios/etiosHatchback/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/etios/etiosHatchback/imagenModeloStore.jpg",
        miniatura: "/images/modelos/etios/etiosHatchback/miniatura.png",
        interior: "/images/modelos/etios/etiosHatchback/interior.jpg",
        exterior: "/images/modelos/etios/etiosHatchback/exterior.jpg",
        catalogo: "/images/modelos/etios/etiosHatchback/catalogo.pdf",
        consumo: "/images/modelos/etios/etiosHatchback/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/etios/etiosHatchback/interior/interior1.jpg",
          "/images/modelos/etios/etiosHatchback/interior/interior2.jpg",
          "/images/modelos/etios/etiosHatchback/interior/interior3.jpg",
          "/images/modelos/etios/etiosHatchback/interior/interior4.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/etios/etiosHatchback/exterior/exterior1.jpg",
          "/images/modelos/etios/etiosHatchback/exterior/exterior2.jpg",
          "/images/modelos/etios/etiosHatchback/exterior/exterior3.jpg",
          "/images/modelos/etios/etiosHatchback/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/etios/etiosHatchback/imagenSalida.jpg",
        versiones: [
          {
            version: "Etios Hatchback - X M/T",
            imagenModelo:
              "/images/modelos/etios/etiosHatchback/etiosHatchbackXMT/rojoMetalizado.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Pack electrico",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Control de estabilidad (VSC) y tracción (TRC)",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },

          {
            version: "Etios Hatchback - XLS Pack M/T",
            imagenModelo:
              "images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackMT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Faros antiniebla delanteros",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil de 7'' con conectividad*: Apple CarPlay® y Android Auto® y Cámara de estacionamiento",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },

          {
            version: "Etios Hatchback - XLS Pack A/T",
            imagenModelo:
              "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackAT/negro.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackAT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackAT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackAT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackAT/grisOscuro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackAT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/etios/etiosHatchback/etiosHatchbackXLSPackAT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Faros antiniebla delanteros",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Control de velocidad crucero",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja automatica de 4 velocidades",
              },
            ],
          },
        ],
      },
      {
        tipo: "Etios Sedán",
        imagen: "/images/modelos/etios/etiosSedan/etiosSedan.png",
        imagenBlur: "/images/modelos/etios/etiosSedan/blur.png",
        fondo: "/images/modelos/etios/etiosSedan/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/etios/etiosSedan/imagenModeloStore.jpg",
        miniatura: "/images/modelos/etios/etiosSedan/miniatura.png",
        interior: "/images/modelos/etios/etiosSedan/interior.jpg",
        exterior: "/images/modelos/etios/etiosSedan/exterior.jpg",
        catalogo: "/images/modelos/etios/etiosSedan/catalogo.pdf",
        consumo: "/images/modelos/etios/etiosSedan/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/etios/etiosSedan/interior/interior1.jpg",
          "/images/modelos/etios/etiosSedan/interior/interior2.jpg",
          "/images/modelos/etios/etiosSedan/interior/interior3.jpg",
          "/images/modelos/etios/etiosSedan/interior/interior4.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/etios/etiosSedan/exterior/exterior1.jpg",
          "/images/modelos/etios/etiosSedan/exterior/exterior2.jpg",
          "/images/modelos/etios/etiosSedan/exterior/exterior3.jpg",
          "/images/modelos/etios/etiosSedan/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/etios/etiosSedan/imagenSalida.jpg",
        versiones: [
          {
            version: "Etios Sedán - X MT",
            imagenModelo:
              "/images/modelos/etios/etiosSedan/etiosSedanXMT/rojoMetalizado.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Pack electrico",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Control de estabilidad (VSC) y tracción (TRC)",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },

          {
            version: "Etios Sedán - XLS Pack 6 M/T",
            imagenModelo:
              "/images/modelos/etios/etiosSedan/etiosSedanXLSPack6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPack6MT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPack6MT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPack6MT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPack6MT/grisOscuro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPack6MT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPack6MT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Faros antiniebla delanteros",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil de 7'' con conectividad*: Apple CarPlay® y Android Auto® y Cámara de estacionamiento",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },

          {
            version: "Etios Sedán - XLS Pack A/T",
            imagenModelo:
              "/images/modelos/etios/etiosSedan/etiosSedanXLSPackAT/negro.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPackAT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPackAT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPackAT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPackAT/grisOscuro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPackAT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/etios/etiosSedan/etiosSedanXLSPackAT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Faros antiniebla delanteros",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil de 7'' con conectividad*: Apple CarPlay® y Android Auto® y Cámara de estacionamiento",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },
        ],
      },
      {
        tipo: "Etios Aibo",
        imagen: "/images/modelos/etios/etiosAibo/etiosAibo.png",
        imagenBlur: "/images/modelos/etios/etiosAibo/blur.png",
        fondo: "/images/modelos/etios/etiosAibo/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/etios/etiosAibo/imagenModeloStore.jpg",
        miniatura: "/images/modelos/etios/etiosAibo/miniatura.png",
        interior: "/images/modelos/etios/etiosAibo/interior.jpg",
        exterior: "/images/modelos/etios/etiosAibo/exterior.jpg",
        catalogo: "/images/modelos/etios/etiosAibo/catalogo.pdf",
        consumo: "/images/modelos/etios/etiosAibo/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/etios/etiosAibo/interior/interior1.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior2.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior3.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior4.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior5.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/etios/etiosAibo/exterior/exterior1.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior2.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior3.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior4.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior5.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior6.jpg",
        ],
        imagenSalida: "/images/modelos/etios/etiosAibo/imagenSalida.jpg",
        versiones: [
          {
            version: "Etios Aibo - 6 M/T",
            imagenModelo: "/images/modelos/etios/etiosAibo/modeloInicial.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosAibo/etiosAibo6MT/blanco.png",
              },
            ],
            datos: [
              {
                caracteristica: "Capacidad de carga: - ",
                descripcion: "450 kg",
              },
              {
                caracteristica: "Interior: - ",
                descripcion:
                  "Estructura metálica de carga y red lateral de protección",
              },
              {
                caracteristica: "Seguridad: - ",
                descripcion: "Control de estabilidad (VSC) y tracción (TRC)",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Etios Aibo",
    tipos: [
      {
        tipo: "Etios Aibo",
        imagen: "/images/modelos/etios/etiosAibo/etiosAibo.png",
        imagenBlur: "/images/modelos/etios/etiosAibo/blur.png",
        fondo: "/images/modelos/etios/etiosAibo/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/etios/etiosAibo/imagenModeloStore.jpg",
        miniatura: "/images/modelos/etios/etiosAibo/miniatura.png",
        interior: "/images/modelos/etios/etiosAibo/interior.jpg",
        exterior: "/images/modelos/etios/etiosAibo/exterior.jpg",
        catalogo: "/images/modelos/etios/etiosAibo/catalogo.pdf",
        consumo: "/images/modelos/etios/etiosAibo/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/etios/etiosAibo/interior/interior1.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior2.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior3.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior4.jpg",
          "/images/modelos/etios/etiosAibo/interior/interior5.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/etios/etiosAibo/exterior/exterior1.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior2.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior3.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior4.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior5.jpg",
          "/images/modelos/etios/etiosAibo/exterior/exterior6.jpg",
        ],
        imagenSalida: "/images/modelos/etios/etiosAibo/imagenSalida.jpg",
        versiones: [
          {
            version: "Etios Aibo - 6 M/T",
            imagenModelo: "/images/modelos/etios/etiosAibo/modeloInicial.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/etios/etiosAibo/etiosAibo6MT/blanco.png",
              },
            ],
            datos: [
              {
                caracteristica: "Capacidad de carga: - ",
                descripcion: "450 kg",
              },
              {
                caracteristica: "Interior: - ",
                descripcion:
                  "Estructura metálica de carga y red lateral de protección",
              },
              {
                caracteristica: "Seguridad: - ",
                descripcion: "Control de estabilidad (VSC) y tracción (TRC)",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Yaris",
    tipos: [
      {
        tipo: "Yaris Hatchback",
        imagen: "/images/modelos/yaris/yarisHatchback/yarisHatchback.png",
        imagenBlur: "/images/modelos/yaris/yarisHatchback/blur.png",
        fondo: "/images/modelos/yaris/yarisHatchback/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/yaris/yarisHatchback/imagenModeloStore.jpg",
        miniatura: "/images/modelos/yaris/yarisHatchback/miniatura.png",
        interior: "/images/modelos/yaris/yarisHatchback/interior.jpg",
        exterior: "/images/modelos/yaris/yarisHatchback/exterior.jpg",
        catalogo: "/images/modelos/yaris/yarisHatchback/catalogo.pdf",
        consumo: "/images/modelos/yaris/yarisHatchback/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/yaris/yarisHatchback/interior/interior1.jpg",
          "/images/modelos/yaris/yarisHatchback/interior/interior2.jpg",
          "/images/modelos/yaris/yarisHatchback/interior/interior3.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/yaris/yarisHatchback/exterior/exterior1.jpg",
          "/images/modelos/yaris/yarisHatchback/exterior/exterior2.jpg",
          "/images/modelos/yaris/yarisHatchback/exterior/exterior3.jpg",
          "/images/modelos/yaris/yarisHatchback/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/yaris/yarisHatchback/imagenSalida.jpg",
        versiones: [
          {
            version: "Yaris Hatchback XS MT",
            imagenModelo:
              "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXSMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Pack electrico",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil LCD de 7 con conectividad: Apple CarPlay®  &  Android Auto®*",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },

          {
            version: "Yaris Hatchback XLS MT",
            imagenModelo:
              "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil LCD de 7” con conectividad: Apple CarPlay® & Android Auto®*",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 15",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },

          {
            version: "Yaris Hatchback XLS CVT",
            imagenModelo:
              "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil LCD de 7” con conectividad: Apple CarPlay® & Android Auto®*",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 15",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja automática CVT de 7 velocidades",
              },
            ],
          },

          {
            version: "Yaris Hatchback XLS PACK CVT",
            imagenModelo:
              "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackXLSPACKCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Tapizado de cuero natural y ecológico",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "7 airbags y Toyota Safety Sense*",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 16",
              },
            ],
          },

          {
            version: "Yaris Hatchback S CVT",
            imagenModelo:
              "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisHatchback/yarisHatchbackSCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Techo solar eléctrico",
              },
              {
                caracteristica: "Exterior - ",
                descripcion: "Ópticas delanteras LED con regulación en altura",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "7 airbags y Toyota Safety Sense*",
              },
            ],
          },
        ],
      },
      {
        tipo: "Yaris Sedán",
        imagen: "/images/modelos/yaris/yarisSedan/yarisSedan.png",
        imagenBlur: "/images/modelos/yaris/yarisSedan/blur.png",
        fondo: "/images/modelos/yaris/yarisSedan/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/yaris/yarisSedan/imagenModeloStore.jpg",
        miniatura: "/images/modelos/yaris/yarisSedan/miniatura.png",
        interior: "/images/modelos/yaris/yarisSedan/interior.jpg",
        exterior: "/images/modelos/yaris/yarisSedan/exterior.jpg",
        catalogo: "/images/modelos/yaris/yarisSedan/catalogo.pdf",
        consumo: "/images/modelos/yaris/yarisSedan/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/yaris/yarisSedan/interior/interior1.jpg",
          "/images/modelos/yaris/yarisSedan/interior/interior2.jpg",
          "/images/modelos/yaris/yarisSedan/interior/interior3.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/yaris/yarisSedan/exterior/exterior1.jpg",
          "/images/modelos/yaris/yarisSedan/exterior/exterior2.jpg",
          "/images/modelos/yaris/yarisSedan/exterior/exterior3.jpg",
          "/images/modelos/yaris/yarisSedan/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/yaris/yarisSedan/imagenSalida.jpg",
        versiones: [
          {
            version: "Yaris Sedán XLS MT",
            imagenModelo:
              "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil LCD de 7” con conectividad: Apple CarPlay® & Android Auto®**",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 15",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },
          {
            version: "Yaris Sedán XLS CVT",
            imagenModelo:
              "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil LCD de 7” con conectividad: Apple CarPlay® & Android Auto®**",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 15",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja automática CVT de 7 velocidades",
              },
            ],
          },
          {
            version: "Yaris Sedán XLS PACK CVT",
            imagenModelo:
              "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yaris/yarisSedan/yarisSedanXLSPACKCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Tapizado de cuero natural y ecológico",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "7 airbags y Toyota Safety Sense*",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 16",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    nombre: "GR Yaris",
    tipos: [
      {
        tipo: "GR Yaris",
        imagen: "/images/modelos/yarisGr/yarisGr/yarisGr.jpg",
        imagenBlur: "/images/modelos/yarisGr/yarisGr/blur.jpg",
        fondo: "/images/modelos/yarisGr/yarisGr/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/yarisGr/yarisGr/imagenModeloStore.jpg",
        miniatura: "/images/modelos/yarisGr/yarisGr/miniatura.jpg",
        interior: "/images/modelos/yarisGr/yarisGr/interior.jpg",
        exterior: "/images/modelos/yarisGr/yarisGr/exterior.jpg",
        catalogo: "/images/modelos/yarisGr/yarisGr/catalogo.pdf",
        consumo: "/images/modelos/yarisGr/yarisGr/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/yarisGr/yarisGr/interior/interior1.jpg",
          "/images/modelos/yarisGr/yarisGr/interior/interior2.jpg",
          "/images/modelos/yarisGr/yarisGr/interior/interior3.jpg",
          "/images/modelos/yarisGr/yarisGr/interior/interior4.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/yarisGr/yarisGr/exterior/exterior1.jpg",
          "/images/modelos/yarisGr/yarisGr/exterior/exterior2.jpg",
          "/images/modelos/yarisGr/yarisGr/exterior/exterior3.jpg",
          "/images/modelos/yarisGr/yarisGr/exterior/exterior4.jpg",
          "/images/modelos/yarisGr/yarisGr/exterior/exterior5.jpg",
          "/images/modelos/yarisGr/yarisGr/exterior/exterior6.jpg",
        ],
        imagenSalida: "/images/modelos/yarisGr/yarisGr/imagenSalida.jpg",
        versiones: [
          {
            version: "GR Yaris",
            imagenModelo: "/images/modelos/yarisGr/yarisGr/modeloInicial.jpg",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/yarisGr/yarisGr/yarisGr/blancoPerlado.jpg",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen: "/images/modelos/yarisGr/yarisGr/yarisGr/negro.jpg",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/yarisGr/yarisGr/yarisGr/rojoMetalizado.jpg",
              },
            ],
            datos: [
              {
                caracteristica: "Performance: - ",
                descripcion:
                  "Motor de 3 cilindros del GR Yaris produce 272 cv y 370 Nm",
              },
              {
                caracteristica: "Performance: - ",
                descripcion:
                  "Llantas BBS forjadas 18”, Suspensión GR , Torsen delantero y trasero LSD, Calipers de freno color rojo ",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Circuit Pack, lo último en respuesta y precisión de manejo.",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    nombre: "Corolla",
    tipos: [
      {
        tipo: "Corolla",
        imagen: "images/modelos/corolla/corolla/corolla.png",
        imagenBlur: "images/modelos/corolla/corolla/blur.png",
        fondo: "images/modelos/corolla/corolla/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "images/modelos/corolla/corolla/imagenModeloStore.jpg",
        miniatura: "images/modelos/corolla/corolla/miniatura.png",
        interior: "images/modelos/corolla/corolla/interior.jpg",
        exterior: "images/modelos/corolla/corolla/exterior.jpg",
        catalogo: "images/modelos/corolla/corolla/catalogo.pdf",
        consumo: "images/modelos/corolla/corolla/consumo.pdf",
        imagenesInterior: [
          "images/modelos/corolla/corolla/interior/interior1.jpg",
          "images/modelos/corolla/corolla/interior/interior2.jpg",
        ],
        imagenesExterior: [
          "images/modelos/corolla/corolla/exterior/exterior1.jpg",
          "images/modelos/corolla/corolla/exterior/exterior2.jpg",
          "images/modelos/corolla/corolla/exterior/exterior3.jpg",
        ],
        imagenSalida: "images/modelos/corolla/corolla/imagenSalida.jpg",
        versiones: [
          {
            version: "Corolla 2.0 XLI MT",
            imagenModelo:
              "images/modelos/corolla/corolla/corolla20XLIMT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Super Blanco",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Nueva motorización 2.0L Dynamic Force con 170 CV y 200 NM.",
              },
              {
                caracteristica: "Conectividad - ",
                descripcion:
                  "Equipo multimedia de 8” con conectividad Apple CarPlay® y Android Auto®*",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Monitor de cámara de estacionamiento en pantalla táctil de 8”",
              },
            ],
          },

          {
            version: "Corolla 2.0 XLI CVT",
            imagenModelo:
              "images/modelos/corolla/corolla/corolla20XLIMT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Super Blanco",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XLIMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Nueva motorización 2.0L Dynamic Force con 170 CV y 200 NM.",
              },
              {
                caracteristica: "Conectividad - ",
                descripcion:
                  "Equipo multimedia de 8” con conectividad Apple CarPlay® y Android Auto®*",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Monitor de cámara de estacionamiento en pantalla táctil de 8”",
              },
            ],
          },
          {
            version: "Corolla 2.0 XEI MT",
            imagenModelo:
              "images/modelos/corolla/corolla/corolla20XEIMT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Super Blanco",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEIMT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEIMT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEIMT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEIMT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEIMT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEIMT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEIMT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Techo solar con apertura eléctrica.",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion: "Control de velocidad crucero.",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Climatizador automático digital Bi-Zona.",
              },
            ],
          },

          {
            version: "Corolla 2.0 XEI CVT",
            imagenModelo:
              "images/modelos/corolla/corolla/corolla20XEICVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Super Blanco",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEICVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEICVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEICVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEICVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEICVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEICVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20XEICVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort - ",
                descripcion: "Techo solar con apertura eléctrica.",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion: "Control de velocidad crucero.",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Climatizador automático digital Bi-Zona.",
              },
            ],
          },

          {
            version: "Corolla 2.0 SEG CVT",
            imagenModelo:
              "images/modelos/corolla/corolla/corolla20SEGCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Super Blanco",
                imagen:
                  "images/modelos/corolla/corolla/corolla20SEGCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20SEGCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "images/modelos/corolla/corolla/corolla20SEGCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20SEGCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20SEGCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corolla/corolla20SEGCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corolla/corolla20SEGCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion: "Levas al volante y modo de conducción deportiva.",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Asientos tapizados en cuero natural y ecológico.",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Paquete de seguridad activa Toyota Safety Sense**",
              },
            ],
          },
        ],
      },
      {
        tipo: "Corolla Hybrid",
        imagen: "images/modelos/corolla/corollaHybrid/corollaHybrid.png",
        imagenBlur: "images/modelos/corolla/corollaHybrid/blur.png",
        fondo: "images/modelos/corolla/corollaHybrid/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "images/modelos/corolla/corollaHybrid/imagenModeloStore.jpg",
        miniatura: "images/modelos/corolla/corollaHybrid/miniatura.png",
        interior: "images/modelos/corolla/corollaHybrid/interior.jpg",
        exterior: "images/modelos/corolla/corollaHybrid/exterior.jpg",
        catalogo: "images/modelos/corolla/corollaHybrid/catalogo.pdf",
        consumo: "images/modelos/corolla/corollaHybrid/consumo.pdf",
        imagenesInterior: [
          "images/modelos/corolla/corollaHybrid/interior/interior1.jpg",
          "images/modelos/corolla/corollaHybrid/interior/interior2.jpg",
        ],
        imagenesExterior: [
          "images/modelos/corolla/corollaHybrid/exterior/exterior1.jpg",
          "images/modelos/corolla/corollaHybrid/exterior/exterior2.jpg",
          "images/modelos/corolla/corollaHybrid/exterior/exterior3.jpg",
          "images/modelos/corolla/corollaHybrid/exterior/exterior4.jpg",
        ],
        imagenSalida: "images/modelos/corolla/corollaHybrid/imagenSalida.jpg",
        versiones: [
          {
            version: "Corolla HV 1.8 SEG eCVT",
            imagenModelo:
              "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18SEGeCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil LCD de 7” con conectividad: Apple CarPlay® & Android Auto®**",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 15",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },
          {
            version: "Corolla HV 1.8 XEI eCVT",
            imagenModelo:
              "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/grisOscuro.png",
              },
              {
                color: "rgb(44, 56, 68)",
                nombre: "Gris Azulado",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/grisAzulado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corollaHybrid/corollaHV18XEIeCVT/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil LCD de 7” con conectividad: Apple CarPlay® & Android Auto®**",
              },
              {
                caracteristica: "Diseño - ",
                descripcion: "Llantas de aleación de 15",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Caja manual de 6 velocidades",
              },
            ],
          },
        ],
      },
      {
        tipo: "Corolla GR-Sport",
        imagen: "/images/modelos/corolla/corollaGrSport/corollaGrSport.png",
        imagenBlur: "/images/modelos/corolla/corollaGrSport/blur.png",
        fondo: "/images/modelos/corolla/corollaGrSport/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/corolla/corollaGrSport/imagenModeloStore.jpg",
        miniatura: "/images/modelos/corolla/corollaGrSport/miniatura.png",
        interior: "/images/modelos/corolla/corollaGrSport/interior.jpg",
        exterior: "/images/modelos/corolla/corollaGrSport/exterior.jpg",
        catalogo: "/images/modelos/corolla/corollaGrSport/catalogo.pdf",
        consumo: "/images/modelos/corolla/corollaGrSport/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/corolla/corollaGrSport/interior/interior1.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior2.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior3.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior4.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior5.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior6.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/corolla/corollaGrSport/exterior/exterior1.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior2.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior3.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior4.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior5.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior6.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior7.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior8.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior9.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior10.jpg",
        ],
        imagenSalida: "/images/modelos/corolla/corollaGrSport/imagenSalida.jpg",
        versiones: [
          {
            version: "Corolla GR-S",
            imagenModelo:
              "images/modelos/corolla/corollaGrSport/corollaGRS/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/corolla/corollaGrSport/corollaGRS/blanco.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corollaGrSport/corollaGRS/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corollaGrSport/corollaGRS/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Emblema GR-S y Spoiler trasero",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Suspensión deportiva GR",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Asientos tapizados de cuero natural y ecológico combinado con Ultrasuede y detalles en rojo",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    nombre: "Corolla GR-Sport",
    tipos: [
      {
        tipo: "Corolla GR-Sport",
        imagen: "/images/modelos/corolla/corollaGrSport/corollaGrSport.png",
        imagenBlur: "/images/modelos/corolla/corollaGrSport/blur.png",
        fondo: "/images/modelos/corolla/corollaGrSport/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/corolla/corollaGrSport/imagenModeloStore.jpg",
        miniatura: "/images/modelos/corolla/corollaGrSport/miniatura.png",
        interior: "/images/modelos/corolla/corollaGrSport/interior.jpg",
        exterior: "/images/modelos/corolla/corollaGrSport/exterior.jpg",
        catalogo: "/images/modelos/corolla/corollaGrSport/catalogo.pdf",
        consumo: "/images/modelos/corolla/corollaGrSport/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/corolla/corollaGrSport/interior/interior1.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior2.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior3.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior4.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior5.jpg",
          "/images/modelos/corolla/corollaGrSport/interior/interior6.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/corolla/corollaGrSport/exterior/exterior1.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior2.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior3.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior4.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior5.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior6.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior7.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior8.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior9.jpg",
          "/images/modelos/corolla/corollaGrSport/exterior/exterior10.jpg",
        ],
        imagenSalida: "/images/modelos/corolla/corollaGrSport/imagenSalida.jpg",
        versiones: [
          {
            version: "Corolla GR-S",
            imagenModelo:
              "images/modelos/corolla/corollaGrSport/corollaGRS/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/corolla/corollaGrSport/corollaGRS/blanco.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "images/modelos/corolla/corollaGrSport/corollaGRS/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "images/modelos/corolla/corollaGrSport/corollaGRS/rojoMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Emblema GR-S y Spoiler trasero",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Suspensión deportiva GR",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Asientos tapizados de cuero natural y ecológico combinado con Ultrasuede y detalles en rojo",
              },
            ],
          },
        ],
      },
    ],
  },
  /* {
    nombre: "Prius",
    tipos: [
      {
        tipo: "Prius",
        imagen: "/images/modelos/prius/prius/prius.png",
        imagenBlur: "/images/modelos/prius/prius/blur.png",
        fondo: "/images/modelos/prius/prius/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore: "/images/modelos/prius/prius/imagenModeloStore.jpg",
        miniatura: "/images/modelos/prius/prius/miniatura.png",
        interior: "/images/modelos/prius/prius/interior.jpg",
        exterior: "/images/modelos/prius/prius/exterior.jpg",
        catalogo: "/images/modelos/prius/prius/catalogo.pdf",
        consumo: "/images/modelos/prius/prius/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/prius/prius/interior/interior1.jpg",
          "/images/modelos/prius/prius/interior/interior2.jpg",
          "/images/modelos/prius/prius/interior/interior3.jpg",
          "/images/modelos/prius/prius/interior/interior4.jpg",
          "/images/modelos/prius/prius/interior/interior5.jpg",
          "/images/modelos/prius/prius/interior/interior6.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/prius/prius/exterior/exterior1.jpg",
          "/images/modelos/prius/prius/exterior/exterior2.jpg",
          "/images/modelos/prius/prius/exterior/exterior3.jpg",
          "/images/modelos/prius/prius/exterior/exterior4.jpg",
          "/images/modelos/prius/prius/exterior/exterior5.jpg",
          "/images/modelos/prius/prius/exterior/exterior6.jpg",
          "/images/modelos/prius/prius/exterior/exterior7.jpg",
        ],
        imagenSalida: "/images/modelos/prius/prius/imagenSalida.jpg",
        versiones: [
          {
            version: "Prius",
            imagenModelo: "/images/modelos/prius/prius/prius/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Super Blanco",
                imagen: "/images/modelos/prius/prius/prius/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen: "/images/modelos/prius/prius/prius/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen: "/images/modelos/prius/prius/prius/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Claro Metalizado",
                imagen:
                  "/images/modelos/prius/prius/prius/grisClaroMetalizado.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen: "/images/modelos/prius/prius/prius/grisOscuro.png",
              },
              {
                color: "rgb(52, 188, 236)",
                nombre: "Azulado Metalizado",
                imagen: "/images/modelos/prius/prius/prius/azulMetalizado.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen: "/images/modelos/prius/prius/prius/negro.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen: "/images/modelos/prius/prius/prius/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion: "Faros delanteros Bi-LED con nivelación",
              },
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Audio JBL con pantalla de 7” con Android Auto® y Apple CarPlay®**",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense*. 7 Airbags,  Control de estabilidad (VSC),  Control de tracción (TRC).",
              },
            ],
          },
        ],
      },
    ],
  }, */
  {
    nombre: "Camry",
    tipos: [
      {
        tipo: "Camry",
        imagen: "/images/modelos/camry/camry/camry.png",
        imagenBlur: "/images/modelos/camry/camry/blur.png",
        fondo: "/images/modelos/camry/camry/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore: "/images/modelos/camry/camry/imagenModeloStore.jpg",
        miniatura: "/images/modelos/camry/camry/miniatura.png",
        interior: "/images/modelos/camry/camry/interior.jpg",
        exterior: "/images/modelos/camry/camry/exterior.jpg",
        catalogo: "/images/modelos/camry/camry/catalogo.pdf",
        consumo: "/images/modelos/camry/camry/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/camry/camry/interior/interior1.jpg",
          "/images/modelos/camry/camry/interior/interior2.jpg",
          "/images/modelos/camry/camry/interior/interior3.jpg",
          "/images/modelos/camry/camry/interior/interior4.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/camry/camry/exterior/exterior1.jpg",
          "/images/modelos/camry/camry/exterior/exterior2.jpg",
          "/images/modelos/camry/camry/exterior/exterior3.jpg",
          "/images/modelos/camry/camry/exterior/exterior4.jpg",
          "/images/modelos/camry/camry/exterior/exterior5.jpg",
        ],
        imagenSalida: "/images/modelos/camry/camry/imagenSalida.jpg",
        versiones: [
          {
            version: "Camry HV 2.5 eCVT",
            imagenModelo:
              "/images/modelos/camry/camry/camryHV25eCVT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Super Blanco",
                imagen: "/images/modelos/camry/camry/camryHV25eCVT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/camry/camry/camryHV25eCVT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/camry/camry/camryHV25eCVT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Claro Metalizado",
                imagen:
                  "/images/modelos/camry/camry/camryHV25eCVT/grisAcero.png",
              },

              {
                color: "rgb(37, 40, 80)",
                nombre: "Azulado Oscuro",
                imagen:
                  "/images/modelos/camry/camry/camryHV25eCVT/azulOscuro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro Metalizado",
                imagen: "/images/modelos/camry/camry/camryHV25eCVT/negro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Grafito Metalizado",
                imagen:
                  "/images/modelos/camry/camry/camryHV25eCVT/grafitoMetalizado.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen: "/images/modelos/camry/camry/camryHV25eCVT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Motor 2.5L Híbrido (218cv) DOHC con sistema VVT-iE (admisión) y VVT-i (escape)",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Climatizador automático digital de 3 zonas.",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense*, 9 Airbags,  Control de estabilidad (VSC) y Control de tracción (TRC).",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    nombre: "Hilux",
    tipos: [
      {
        tipo: "Hilux DX/SR",
        imagen: "/images/modelos/hilux/hiluxDXSR/hiluxDXSR.png",
        imagenBlur: "/images/modelos/hilux/hiluxDXSR/blur.png",
        fondo: "/images/modelos/hilux/hiluxDXSR/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hilux/hiluxDXSR/imagenModeloStore.jpg",
        miniatura: "/images/modelos/hilux/hiluxDXSR/miniatura.png",
        interior: "/images/modelos/hilux/hiluxDXSR/interior.jpg",
        exterior: "/images/modelos/hilux/hiluxDXSR/exterior.jpg",
        catalogo: "/images/modelos/hilux/hiluxDXSR/catalogo.pdf",
        consumo: "/images/modelos/hilux/hiluxDXSR/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/hilux/hiluxDXSR/interior/interior1.jpg",
          "/images/modelos/hilux/hiluxDXSR/interior/interior2.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hilux/hiluxDXSR/exterior/exterior1.jpg",
          "/images/modelos/hilux/hiluxDXSR/exterior/exterior2.jpg",
          "/images/modelos/hilux/hiluxDXSR/exterior/exterior3.jpg",
          "/images/modelos/hilux/hiluxDXSR/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/hilux/hiluxDXSR/imagenSalida.jpg",
        versiones: [
          {
            version: "Hilux 4X2 CC DX 2.4 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Exterior - ",
                descripcion:
                  "Faros delanteros halógenos con regulación en altura y Sistema 'Follow me home'",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2)",
              },
            ],
          },
          {
            version: "Hilux 4X4 CC DX 2.4 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CCDX24TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Exterior - ",
                descripcion:
                  "Faros delanteros halógenos con regulación en altura y Sistema 'Follow me home'",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2)",
              },
            ],
          },
          {
            version: "Hilux 4X2 CS DX 2.4 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Exterior - ",
                descripcion: "Cobertor de caja de carga",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2",
              },
            ],
          },
          {
            version: "Hilux 4X4 CS DX 2.4 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2CSDX24TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Exterior - ",
                descripcion: "Cobertor de caja de carga",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2",
              },
            ],
          },
          {
            version: "Hilux 4X2 DC DX 2.4 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Exterior - ",
                descripcion:
                  "Espejos exteriores con regulación eléctrica y luz de giro incorporada",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2",
              },
            ],
          },
          {
            version: "Hilux 4X4 DC DX 2.4 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X2DCDX24TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Exterior - ",
                descripcion:
                  "Espejos exteriores con regulación eléctrica y luz de giro incorporada",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2",
              },
            ],
          },
          {
            version: "Hilux 4X4 DC SR 2.8 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion:
                  "Display de información múltiple con pantalla a color de 4,2' (TFT)",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2",
              },
            ],
          },
          {
            version: "Hilux 4X4 DC SR 2.8 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxDXSR/hilux4X4DCSR28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion:
                  "Display de información múltiple con pantalla a color de 4,2' (TFT)",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Audio con pantalla táctil de 8“ y Control de velocidad crucero",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (conductor y acompañante), de rodilla (conductor), laterales (x2) y de cortina (x2",
              },
            ],
          },
        ],
      },
      {
        /* tipo: "Hilux SRV/SRX", */
        tipo: "Hilux SRV",
        imagen: "/images/modelos/hilux/hiluxSRVSRX/hiluxSRVSRX.png",
        imagenBlur: "/images/modelos/hilux/hiluxSRVSRX/blur.png",
        fondo: "/images/modelos/hilux/hiluxSRVSRX/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hilux/hiluxSRVSRX/imagenModeloStore.jpg",
        miniatura: "/images/modelos/hilux/hiluxSRVSRX/miniatura.png",
        interior: "/images/modelos/hilux/hiluxSRVSRX/interior.jpg",
        exterior: "/images/modelos/hilux/hiluxSRVSRX/exterior.jpg",
        catalogo: "/images/modelos/hilux/hiluxSRVSRX/catalogo.pdf",
        consumo: "/images/modelos/hilux/hiluxSRVSRX/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior1.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior2.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior3.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior4.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior5.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior6.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior1.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior2.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior3.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior4.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior5.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior6.jpg",
        ],
        imagenSalida: "/images/modelos/hilux/hiluxSRVSRX/imagenSalida.jpg",
        versiones: [
          {
            version: "Hilux 4X2 DC SRV 2.8 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion: "204 CV de potencia máxima",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Sensores de estacionamiento delanteros (x2) y traseros (x4)",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (x2), laterales (x2), de cortina (x2) y de rodilla (conductor)",
              },
            ],
          },
          {
            version: "Hilux 4X2 DC SRV 2.8 TDI 6 AT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion: "204 CV de potencia máxima",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Sensores de estacionamiento delanteros (x2) y traseros (x4)",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (x2), laterales (x2), de cortina (x2) y de rodilla (conductor)",
              },
            ],
          },
          /* {
            version: "Hilux 4X2 DC SRX 2.8 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blancoPerlado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/plataMetalizado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de audio Premium JBL® con 8 parlantes, subwoofer y sistema de visión 360°",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Butacas delanteras con función de ventilación",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Toyota Safety Sense*",
              },
            ],
          }, */
          /*   {
            version: "Hilux 4X2 DC SRX 2.8 TDI 6 AT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blancoPerlado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/plataMetalizado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de audio Premium JBL® con 8 parlantes, subwoofer y sistema de visión 360°",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Butacas delanteras con función de ventilación",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Toyota Safety Sense*",
              },
            ],
          }, */
          {
            version: "Hilux 4X4 DC SRV 2.8 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion: "204 CV de potencia máxima",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Sensores de estacionamiento delanteros (x2) y traseros (x4)",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (x2), laterales (x2), de cortina (x2) y de rodilla (conductor)",
              },
            ],
          },
          {
            version: "Hilux 4X4 DC SRV 2.8 TDI 6 AT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRV28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion: "204 CV de potencia máxima",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Sensores de estacionamiento delanteros (x2) y traseros (x4)",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "7 airbags: frontales (x2), laterales (x2), de cortina (x2) y de rodilla (conductor)",
              },
            ],
          },
          /* {
            version: "Hilux 4X4 DC SRX 2.8 TDI 6 MT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blancoPerlado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/plataMetalizado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de audio Premium JBL® con 8 parlantes, subwoofer y sistema de visión 360°",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Butacas delanteras con función de ventilación",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Toyota Safety Sense*",
              },
            ],
          }, */
          /* {
            version: "Hilux 4X4 DC SRX 2.8 TDI 6 AT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/blancoPerlado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/plataMetalizado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/hilux4X2DCSRX28TDI6MT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de audio Premium JBL® con 8 parlantes, subwoofer y sistema de visión 360°",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Butacas delanteras con función de ventilación",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Toyota Safety Sense*",
              },
            ],
          }, */
        ],
      },
      {
        tipo: "Hilux SRX",
        imagen: "/images/modelos/hilux/hiluxSRVSRX/hiluxSRXNEW.png",
        imagenBlur: "/images/modelos/hilux/hiluxSRVSRX/blur.png",
        fondo: "/images/modelos/hilux/hiluxSRVSRX/fondoSRX.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hilux/hiluxSRVSRX/imagenModeloStoreSRX.jpg",
        miniatura: "/images/modelos/hilux/hiluxSRVSRX/miniaturaNEW.png",
        interior: "/images/modelos/hilux/hiluxSRVSRX/interiorNEW.jpg",
        exterior: "/images/modelos/hilux/hiluxSRVSRX/exteriorNEW.jpg",
        catalogo: "/images/modelos/hilux/hiluxSRVSRX/catalogoNEW.pdf",
        consumo: "/images/modelos/hilux/hiluxSRVSRX/consumoNEW.pdf",
        imagenesInterior: [
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior1NEW.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior2NEW.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior3NEW.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/interior/interior4NEW.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior1NEW.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior2NEW.jpg",
          "/images/modelos/hilux/hiluxSRVSRX/exterior/exterior3NEW.jpg",
        ],
        imagenSalida: "/images/modelos/hilux/hiluxSRVSRX/imagenSalidaNEW.jpg",
        versiones: [
          {
            version: "Hilux SRX 4x2 AT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/blancoPerlado.png",
            colores: [
              {
                color: "rgb(195, 195, 192)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/blancoPerlado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio con pantalla táctil de 9”, conectividad inalámbrica con 8 parlantes, subwoofer y sistema de visión 360°.",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Automática de 6 velocidades y tracción 4x2.",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Paquete de seguridad activa Toyota Safety Sense*.",
              },
            ],
          },
          {
            version: "Hilux SRX 4X4 AT",
            imagenModelo:
              "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/blancoPerlado.png",
            colores: [
              {
                color: "rgb(195, 195, 192)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/blancoPerlado.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Plata Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/grisPlata.png",
              },
              {
                color: "rgb(230, 230, 232)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/grisOscuro.png",
              },

              {
                color: "rgb(29, 22, 15)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/negro.png",
              },
              {
                color: "rgb(142, 29, 39)",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/hilux/hiluxSRVSRX/HiluxSRX2024/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion:
                  "Se destacan los faros delanteros Bi – LED, luces diurnas (DRL) y antiniebla de LED junto con las llantas de aleación de 17” y 18”.",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Las versiones SRX cuentan con tapizado de cuero natural.",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Todas las versiones vienen equipadas con 7 airbags.",
              },
            ],
          },
        ],
      },
    ],
  },

  {
    nombre: "Hiace Furgón",
    tipos: [
      {
        tipo: "Hiace Furgón",
        imagen: "/images/modelos/hiaceFurgon/hiaceFurgon/hiaceFurgon.png",
        imagenBlur: "/images/modelos/hiaceFurgon/hiaceFurgon/blur.png",
        fondo: "/images/modelos/hiaceFurgon/hiaceFurgon/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hiaceFurgon/hiaceFurgon/imagenModeloStore.jpg",
        miniatura: "/images/modelos/hiaceFurgon/hiaceFurgon/miniatura.png",
        interior: "/images/modelos/hiaceFurgon/hiaceFurgon/interior.jpg",
        exterior: "/images/modelos/hiaceFurgon/hiaceFurgon/exterior.jpg",
        catalogo: "/images/modelos/hiaceFurgon/hiaceFurgon/catalogo.pdf",
        consumo: "/images/modelos/hiaceFurgon/hiaceFurgon/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior4.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior5.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior6.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior7.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior8.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior9.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior10.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/interior/interior11.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior4.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior5.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior6.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior7.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior8.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior9.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior10.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior11.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior12.jpg",
          "/images/modelos/hiaceFurgon/hiaceFurgon/exterior/exterior13.jpg",
        ],
        imagenSalida:
          "/images/modelos/hiaceFurgon/hiaceFurgon/imagenSalida.jpg",
        versiones: [
          {
            version: "Hiace Furgón L1H1 2.8 TDI 6AT 3A 4P",
            imagenModelo:
              "/images/modelos/hiaceFurgon/hiaceFurgon/hiaceFurgonL1H128TDI6AT3A4P/blanco.png",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceFurgon/hiaceFurgonL1H128TDI6AT3A4P/blanco.png",
              },
            ],
            datos: [
              {
                caracteristica: "Volumen de carga - ",
                descripcion: "6,2m3",
              },
              {
                caracteristica: "Segura - ",
                descripcion: "Airbags frontales (x2) para 3 ocupantes",
              },
              {
                caracteristica: "Confiable - ",
                descripcion: "Motor 1GD y caja de 6 marchas de Hilux.",
              },
            ],
          },
          {
            version: "Hiace Furgón L2H2 2.8 TDI 6AT 3A 5P",
            imagenModelo:
              "/images/modelos/hiaceFurgon/hiaceFurgon/hiaceFurgonL2H228TDI6AT3A5P/blanco.png",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceFurgon/hiaceFurgonL2H228TDI6AT3A5P/blanco.png",
              },
            ],
            datos: [
              {
                caracteristica: "Volumen de carga - ",
                descripcion: "9,3m3",
              },
              {
                caracteristica: "Cómoda - ",
                descripcion: "Relajada posición de manejo",
              },
              {
                caracteristica: "Práctica - ",
                descripcion: "Doble puerta lateral",
              },
            ],
          },
        ],
      },
      {
        tipo: "Hiace Commuter",
        imagen: "/images/modelos/hiaceFurgon/hiaceCommuter/modelo.png",
        imagenBlur: "/images/modelos/hiaceFurgon/hiaceCommuter/blur.png",
        fondo: "/images/modelos/hiaceFurgon/hiaceCommuter/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hiaceFurgon/hiaceCommuter/imagenModeloStore.jpg",
        miniatura: "/images/modelos/hiaceFurgon/hiaceCommuter/miniatura.png",
        interior: "/images/modelos/hiaceFurgon/hiaceCommuter/interior.jpg",
        exterior: "/images/modelos/hiaceFurgon/hiaceCommuter/exterior.jpg",
        catalogo: "/images/modelos/hiaceFurgon/hiaceCommuter/catalogo.pdf",
        consumo: "/images/modelos/hiaceFurgon/hiaceCommuter/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior4.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior5.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior4.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior5.jpg",
        ],
        imagenSalida:
          "/images/modelos/hiaceFurgon/hiaceCommuter/imagenSalida.jpg",
        versiones: [
          {
            version: "Hiace Commuter 2.8 TDI 6AT 14A",
            imagenModelo:
              "/images/modelos/hiaceFurgon/hiaceCommuter/hiaceCommuter28TDI6AT14A/blanco.png",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/hiaceFurgon/hiaceCommuter/hiaceCommuter28TDI6AT14A/blanco.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confiable - ",
                descripcion:
                  "Motor 1GD - 163CV y transmisión automática de 6 velocidades.",
              },
              {
                caracteristica: "Práctica - ",
                descripcion:
                  "Luz de cortesía LED, puertos USB, bandeja posavasos y espacios portaobjetos en las plazas traseras.",
              },
              {
                caracteristica: "Cómoda - ",
                descripcion:
                  "excelente confort y un amplio espacio para sus ocupantes.",
              },
            ],
          },
        ],
      },
      {
        tipo: "Hiace Wagon",
        imagen: "/images/modelos/hiaceFurgon/hiaceWagon/modelo.png",
        imagenBlur: "/images/modelos/hiaceFurgon/hiaceWagon/blur.png",
        fondo: "/images/modelos/hiaceFurgon/hiaceWagon/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hiaceFurgon/hiaceWagon/imagenModeloStore.jpg",
        miniatura: "/images/modelos/hiaceFurgon/hiaceWagon/miniatura.png",
        interior: "/images/modelos/hiaceFurgon/hiaceWagon/interior.jpg",
        exterior: "/images/modelos/hiaceFurgon/hiaceWagon/exterior.jpg",
        catalogo: "/images/modelos/hiaceFurgon/hiaceWagon/catalogo.pdf",
        consumo: "/images/modelos/hiaceFurgon/hiaceWagon/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior4.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/hiaceFurgon/hiaceWagon/imagenSalida.jpg",
        versiones: [
          {
            version: "Hiace Wagon 2.8 TDI 6AT 10A",
            imagenModelo:
              "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/negro.png",
            colores: [
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/negro.png",
              },
              {
                color: "rgb(202, 213, 216)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/grisPlata.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/blanco.png",
              },
              {
                color: "rgb(130, 130, 130)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/grisOscuro.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confiable - ",
                descripcion:
                  "Motor 1GD - 163CV y transmisión automática de 6 velocidades.",
              },
              {
                caracteristica: "Práctica - ",
                descripcion:
                  "Doble puerta de acceso lateral con apertura eléctrica.",
              },
              {
                caracteristica: "Segura - ",
                descripcion: "Toyota Safety Sense* y 7 Airbags.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Hiace Commuter",
    tipos: [
      {
        tipo: "Hiace Commuter",
        imagen: "/images/modelos/hiaceFurgon/hiaceCommuter/modelo.png",
        imagenBlur: "/images/modelos/hiaceFurgon/hiaceCommuter/blur.png",
        fondo: "/images/modelos/hiaceFurgon/hiaceCommuter/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hiaceFurgon/hiaceCommuter/imagenModeloStore.jpg",
        miniatura: "/images/modelos/hiaceFurgon/hiaceCommuter/miniatura.png",
        interior: "/images/modelos/hiaceFurgon/hiaceCommuter/interior.jpg",
        exterior: "/images/modelos/hiaceFurgon/hiaceCommuter/exterior.jpg",
        catalogo: "/images/modelos/hiaceFurgon/hiaceCommuter/catalogo.pdf",
        consumo: "/images/modelos/hiaceFurgon/hiaceCommuter/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior4.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/interior/interior5.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior4.jpg",
          "/images/modelos/hiaceFurgon/hiaceCommuter/exterior/exterior5.jpg",
        ],
        imagenSalida:
          "/images/modelos/hiaceFurgon/hiaceCommuter/imagenSalida.jpg",
        versiones: [
          {
            version: "Hiace Commuter 2.8 TDI 6AT 14A",
            imagenModelo:
              "/images/modelos/hiaceFurgon/hiaceCommuter/hiaceCommuter28TDI6AT14A/blanco.png",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/hiaceFurgon/hiaceCommuter/hiaceCommuter28TDI6AT14A/blanco.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confiable - ",
                descripcion:
                  "Motor 1GD - 163CV y transmisión automática de 6 velocidades.",
              },
              {
                caracteristica: "Práctica - ",
                descripcion:
                  "Luz de cortesía LED, puertos USB, bandeja posavasos y espacios portaobjetos en las plazas traseras.",
              },
              {
                caracteristica: "Cómoda - ",
                descripcion:
                  "excelente confort y un amplio espacio para sus ocupantes.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Hiace Wagon",
    tipos: [
      {
        tipo: "Hiace Wagon",
        imagen: "/images/modelos/hiaceFurgon/hiaceWagon/modelo.png",
        imagenBlur: "/images/modelos/hiaceFurgon/hiaceWagon/blur.png",
        fondo: "/images/modelos/hiaceFurgon/hiaceWagon/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/hiaceFurgon/hiaceWagon/imagenModeloStore.jpg",
        miniatura: "/images/modelos/hiaceFurgon/hiaceWagon/miniatura.png",
        interior: "/images/modelos/hiaceFurgon/hiaceWagon/interior.jpg",
        exterior: "/images/modelos/hiaceFurgon/hiaceWagon/exterior.jpg",
        catalogo: "/images/modelos/hiaceFurgon/hiaceWagon/catalogo.pdf",
        consumo: "/images/modelos/hiaceFurgon/hiaceWagon/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/interior/interior4.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior1.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior2.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior3.jpg",
          "/images/modelos/hiaceFurgon/hiaceWagon/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/hiaceFurgon/hiaceWagon/imagenSalida.jpg",
        versiones: [
          {
            version: "Hiace Wagon 2.8 TDI 6AT 10A",
            imagenModelo:
              "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/negro.png",
            colores: [
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/negro.png",
              },
              {
                color: "rgb(202, 213, 216)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/grisPlata.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/blanco.png",
              },
              {
                color: "rgb(130, 130, 130)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/hiaceFurgon/hiaceWagon/hiaceWagon28TDI6AT10A/grisOscuro.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confiable - ",
                descripcion:
                  "Motor 1GD - 163CV y transmisión automática de 6 velocidades.",
              },
              {
                caracteristica: "Práctica - ",
                descripcion:
                  "Doble puerta de acceso lateral con apertura eléctrica.",
              },
              {
                caracteristica: "Segura - ",
                descripcion: "Toyota Safety Sense* y 7 Airbags.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "C-HR",
    tipos: [
      {
        tipo: "C-HR",
        imagen: "/images/modelos/chr/chr/modelo.png",
        imagenBlur: "/images/modelos/chr/chr/blur.png",
        fondo: "/images/modelos/chr/chr/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore: "/images/modelos/chr/chr/imagenModeloStore.jpg",
        miniatura: "/images/modelos/chr/chr/miniatura.png",
        interior: "/images/modelos/chr/chr/interior.jpg",
        exterior: "/images/modelos/chr/chr/exterior.jpg",
        catalogo: "/images/modelos/chr/chr/catalogo.pdf",
        consumo: "/images/modelos/chr/chr/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/chr/chr/interior/interior1.jpg",
          "/images/modelos/chr/chr/interior/interior2.jpg",
          "/images/modelos/chr/chr/interior/interior3.jpg",
          "/images/modelos/chr/chr/interior/interior4.jpg",
          "/images/modelos/chr/chr/interior/interior5.jpg",
          "/images/modelos/chr/chr/interior/interior6.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/chr/chr/exterior/exterior1.jpg",
          "/images/modelos/chr/chr/exterior/exterior2.jpg",
          "/images/modelos/chr/chr/exterior/exterior3.jpg",
          "/images/modelos/chr/chr/exterior/exterior4.jpg",
        ],
        imagenSalida: "/images/modelos/chr/chr/imagenSalida.jpg",
        versiones: [
          {
            version: "C-HR HV 1.8 eCVT",
            imagenModelo: "/images/modelos/chr/chr/CHRHV18eCVT/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen: "/images/modelos/chr/chr/CHRHV18eCVT/blanco.png",
              },
              {
                color: "rgb(255, 238, 207)",
                nombre: "Blanco Perlado",
                imagen: "/images/modelos/chr/chr/CHRHV18eCVT/blancoPerlado.png",
              },
              {
                color: "rgb(220, 223, 252)",
                nombre: "Gris Azulado",
                imagen: "/images/modelos/chr/chr/CHRHV18eCVT/grisAzulado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/chr/chr/CHRHV18eCVT/grisPlataMetalizado.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/chr/chr/CHRHV18eCVT/grisOscuroMetalizado.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen: "/images/modelos/chr/chr/CHRHV18eCVT/negro.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Azul Metalizado",
                imagen:
                  "/images/modelos/chr/chr/CHRHV18eCVT/azulMetalizado.png",
              },
              {
                color: "rgb(251, 36, 36)",
                nombre: "Rojo",
                imagen: "/images/modelos/chr/chr/CHRHV18eCVT/rojo.png",
              },
              {
                color: "rgb(251, 121, 36)",
                nombre: "Naranja",
                imagen: "/images/modelos/chr/chr/CHRHV18eCVT/naranja.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Sistema híbrido auto-recargable: Motor a combustión 1.8L",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de estacionamiento inteligente.  Sistema de conectividad Apple CarPlay® & Android Auto®**",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense*. 7 Airbags, Control de estabilidad (VSC), Control de tracción (TRC)",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Corolla Cross",
    tipos: [
      {
        tipo: "Corolla Cross",
        imagen: "/images/modelos/corollaCross/corollaCross/modelo.png",
        imagenBlur: "/images/modelos/corollaCross/corollaCross/blur.png",
        fondo: "/images/modelos/corollaCross/corollaCross/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/corollaCross/corollaCross/imagenModeloStore.jpg",
        miniatura: "/images/modelos/corollaCross/corollaCross/miniatura.png",
        interior: "/images/modelos/corollaCross/corollaCross/interior.jpg",
        exterior: "/images/modelos/corollaCross/corollaCross/exterior.jpg",
        catalogo: "/images/modelos/corollaCross/corollaCross/catalogo.pdf",
        consumo: "/images/modelos/corollaCross/corollaCross/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/corollaCross/corollaCross/interior/interior1.jpg",
          "/images/modelos/corollaCross/corollaCross/interior/interior2.jpg",
          "/images/modelos/corollaCross/corollaCross/interior/interior3.jpg",
          "/images/modelos/corollaCross/corollaCross/interior/interior4.jpg",
          "/images/modelos/corollaCross/corollaCross/interior/interior5.jpg",
          "/images/modelos/corollaCross/corollaCross/interior/interior6.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/corollaCross/corollaCross/exterior/exterior1.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior2.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior3.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior4.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior5.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior6.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior7.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior8.jpg",
          "/images/modelos/corollaCross/corollaCross/exterior/exterior9.jpg",
        ],
        imagenSalida:
          "/images/modelos/corollaCross/corollaCross/imagenSalida.jpg",
        versiones: [
          {
            version: "XLI 2.0 CVT",
            imagenModelo:
              "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/blanco.png",
              },
              {
                color: "rgb(255, 238, 207)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/negro.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Azul Oscuro Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/azulMetalizado.png",
              },
              {
                color: "rgb(251, 36, 36)",
                nombre: "Rojo",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXLI/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Seguridad - ",
                descripcion: "Paquete de seguridad activa Toyota Safety Sense.",
              },
              {
                caracteristica: "Conectividad - ",
                descripcion:
                  "Equipo multimedia de 9” con conectividad inalámbrica Apple CarPlay® y Android Auto®",
              },
              {
                caracteristica: "Confort Interior - ",
                descripcion:
                  "Freno de mano electrónico con función 'Auto-Hold'",
              },
            ],
          },
          {
            version: "XEI CVT",
            imagenModelo:
              "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/blanco.png",
              },
              {
                color: "rgb(255, 238, 207)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/negro.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Azul Oscuro Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/azulMetalizado.png",
              },
              {
                color: "rgb(251, 36, 36)",
                nombre: "Rojo",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossXEI/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Confort interior - ",
                descripcion:
                  "Amplio espacio interior, freno de estacionamiento electrónico y cargador inalámbrico para smartphones",
              },
              {
                caracteristica: "Exterior - ",
                descripcion: "Espíritu moderno y elegante.",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Todas las versiones están equipadas con Toyota Safety Sense",
              },
            ],
          },
          {
            version: "SEG 2.0 CVT",
            imagenModelo:
              "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/blanco.png",
              },
              {
                color: "rgb(255, 238, 207)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/negro.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Azul Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/azulMetalizado.png",
              },
              {
                color: "rgb(251, 36, 36)",
                nombre: "Rojo",
                imagen:
                  "/images/modelos/corollaCross/corollaCross/corollaCrossSEG/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Exterior - ",
                descripcion:
                  "Diseño frontal completamente renovado, con una nueva parrilla en forma de panal que transmite mayor modernidad y sofisticación.",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Asientos tapizados en cuero natural y ecológico",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Nuevo diseño de llantas de 18”",
              },
            ],
          },
        ],
      },
      {
        tipo: "Corolla Cross Hybrid",
        imagen: "/images/modelos/corollaCross/corollaCrossHybrid/modelo.png",
        imagenBlur: "/images/modelos/corollaCross/corollaCrossHybrid/blur.png",
        fondo: "/images/modelos/corollaCross/corollaCrossHybrid/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/corollaCross/corollaCrossHybrid/imagenModeloStore.jpg",
        miniatura:
          "/images/modelos/corollaCross/corollaCrossHybrid/miniatura.png",
        interior:
          "/images/modelos/corollaCross/corollaCrossHybrid/interior.jpg",
        exterior:
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior.jpg",
        catalogo:
          "/images/modelos/corollaCross/corollaCrossHybrid/catalogo.pdf",
        consumo: "/images/modelos/corollaCross/corollaCrossHybrid/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/corollaCross/corollaCrossHybrid/interior/interior1.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/interior/interior2.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/interior/interior3.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/interior/interior4.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/interior/interior5.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/interior/interior6.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/interior/interior7.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior1.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior2.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior3.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior4.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior5.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior6.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior7.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior8.jpg",
          "/images/modelos/corollaCross/corollaCrossHybrid/exterior/exterior9.jpg",
        ],
        imagenSalida:
          "/images/modelos/corollaCross/corollaCrossHybrid/imagenSalida.jpg",
        versiones: [
          {
            version: "Corolla Cross XEI HEV",
            imagenModelo:
              "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/blanco.png",
              },
              {
                color: "rgb(255, 238, 207)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/negro.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Azul Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/azulMetalizado.png",
              },
              {
                color: "rgb(251, 36, 36)",
                nombre: "Rojo",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossXEIHEV/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Performance - ",
                descripcion: "Motorización 1.8L Hybrid",
              },
              {
                caracteristica: "Conectividad - ",
                descripcion:
                  "Equipo multimedia de 9” con conectividad Apple CarPlay® y Android Auto® inalámbrica**",
              },
              {
                caracteristica: "Exterior - ",
                descripcion: "Llantas de aleación de 18“",
              },
            ],
          },
          {
            version: "Corolla Cross SEG HEV",
            imagenModelo:
              "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/blanco.png",
              },
              {
                color: "rgb(255, 238, 207)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/negro.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Azul Metalizado",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/azulMetalizado.png",
              },
              {
                color: "rgb(251, 36, 36)",
                nombre: "Rojo",
                imagen:
                  "/images/modelos/corollaCross/corollaCrossHybrid/corollaCrossSEGHEV/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Display de información múltiple Full Digital de 12,3",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Portón trasero con sistema de apertura y cierre eléctrico con sensor de manos libres",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Paquete de seguridad activa Toyota Safety Sense*",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "RAV4",
    tipos: [
      {
        tipo: "RAV4",
        imagen: "/images/modelos/rav4/rav4/modelo.png",
        imagenBlur: "/images/modelos/rav4/rav4/blur.png",
        fondo: "/images/modelos/rav4/rav4/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore: "/images/modelos/rav4/rav4/imagenModeloStore.jpg",
        miniatura: "/images/modelos/rav4/rav4/miniatura.png",
        interior: "/images/modelos/rav4/rav4/interior.jpg",
        exterior: "/images/modelos/rav4/rav4/exterior.jpg",
        catalogo: "/images/modelos/rav4/rav4/catalogo.pdf",
        consumo: "/images/modelos/rav4/rav4/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/rav4/rav4/interior/interior1.jpg",
          "/images/modelos/rav4/rav4/interior/interior2.jpg",
          "/images/modelos/rav4/rav4/interior/interior3.jpg",
          "/images/modelos/rav4/rav4/interior/interior4.jpg",
          "/images/modelos/rav4/rav4/interior/interior5.jpg",
          "/images/modelos/rav4/rav4/interior/interior6.jpg",
          "/images/modelos/rav4/rav4/interior/interior7.jpg",
          "/images/modelos/rav4/rav4/interior/interior8.jpg",
          "/images/modelos/rav4/rav4/interior/interior9.jpg",
          "/images/modelos/rav4/rav4/interior/interior10.jpg",
          "/images/modelos/rav4/rav4/interior/interior11.jpg",
          "/images/modelos/rav4/rav4/interior/interior12.jpg",
          "/images/modelos/rav4/rav4/interior/interior13.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/rav4/rav4/exterior/exterior1.jpg",
          "/images/modelos/rav4/rav4/exterior/exterior2.jpg",
        ],
        imagenSalida: "/images/modelos/rav4/rav4/imagenSalida.jpg",
        versiones: [
          {
            version: "RAV4 HV AWD LIMITED CVT",
            imagenModelo:
              "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/blanco.png",
              },
              {
                color: "rgb(255, 238, 207)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/negro.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Celeste Metalizado",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/azulMetalizado.png",
              },
              {
                color: "rgb(23, 50, 65)",
                nombre: "Azul Oscuro Metalizado",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/azulOscuroMetalizado.png",
              },
              {
                color: "rgb(251, 36, 36)",
                nombre: "Rojo",
                imagen:
                  "/images/modelos/rav4/rav4/RAV4HVAWDLIMITEDCVT/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Potencia - ",
                descripcion: "2.5 Its híbrido TNGA (222 CV)",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Sistema de tracción integral inteligenteE-FOUR",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion: "Toyota Safety Sense*",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "SW4",
    tipos: [
      {
        tipo: "SW4",
        imagen: "/images/modelos/sw4/sw4/modelo.png",
        imagenBlur: "/images/modelos/sw4/sw4/blur.png",
        fondo: "/images/modelos/sw4/sw4/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore: "/images/modelos/sw4/sw4/imagenModeloStore.jpg",
        miniatura: "/images/modelos/sw4/sw4/miniatura.png",
        interior: "/images/modelos/sw4/sw4/interior.jpg",
        exterior: "/images/modelos/sw4/sw4/exterior.jpg",
        catalogo: "/images/modelos/sw4/sw4/catalogo.pdf",
        consumo: "/images/modelos/sw4/sw4/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/sw4/sw4/interior/interior1.jpg",
          "/images/modelos/sw4/sw4/interior/interior2.jpg",
          "/images/modelos/sw4/sw4/interior/interior3.jpg",
          "/images/modelos/sw4/sw4/interior/interior4.jpg",
          "/images/modelos/sw4/sw4/interior/interior5.jpg",
          "/images/modelos/sw4/sw4/interior/interior6.jpg",
          "/images/modelos/sw4/sw4/interior/interior7.jpg",
          "/images/modelos/sw4/sw4/interior/interior8.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/sw4/sw4/exterior/exterior1.jpg",
          "/images/modelos/sw4/sw4/exterior/exterior2.jpg",
        ],
        imagenSalida: "/images/modelos/sw4/sw4/imagenSalida.jpg",
        versiones: [
          {
            version: "SW4 4X4 SR 2.8 TDI 6 AT 5A",
            imagenModelo:
              "/images/modelos/sw4/sw4/SW44X4SR28TDI6AT5A/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen: "/images/modelos/sw4/sw4/SW44X4SR28TDI6AT5A/blanco.png",
              },

              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SR28TDI6AT5A/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SR28TDI6AT5A/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen: "/images/modelos/sw4/sw4/SW44X4SR28TDI6AT5A/negro.png",
              },

              {
                color: "rgb(94, 87, 82)",
                nombre: "Marron Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SR28TDI6AT5A/marronMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Faros delanteros bi – LED",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Sensores de estacionamiento delanteros y traseros.",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Motor 2.8l con 204 CV y 500 Nm de torque máximo.",
              },
            ],
          },
          {
            version: "SW4 4X4 SRX 2.8 TDI 6 MT 7A",
            imagenModelo:
              "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen: "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/negro.png",
              },

              {
                color: "rgb(94, 87, 82)",
                nombre: "Marron Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/marronMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de audio Premium JBL con 8 parlantes y subwoofer y sistema de visión 360",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Butacas delanteras con función de ventilación",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense*, Alerta de Punto Ciego (BSM) y Alerta de Tráfico Trasero (RCTA)",
              },
            ],
          },
          {
            version: "SW4 4X4 SRX 2.8 TDI 6 AT 7A",
            imagenModelo:
              "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Super Blanco",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/blancoPerlado.png",
              },
              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen: "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/negro.png",
              },

              {
                color: "rgb(94, 87, 82)",
                nombre: "Marron Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4/SW44X4SRX28TDI6MT7A/marronMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de audio Premium JBL con 8 parlantes y subwoofer y sistema de visión 360",
              },
              {
                caracteristica: "Confort - ",
                descripcion: "Butacas delanteras con función de ventilación",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense*, Alerta de Punto Ciego (BSM) y Alerta de Tráfico Trasero (RCTA)",
              },
            ],
          },
        ],
      },
      {
        tipo: "SW4 DIAMOND",
        imagen: "/images/modelos/sw4/sw4Diamond/modelo.png",
        imagenBlur: "/images/modelos/sw4/sw4Diamond/blur.png",
        fondo: "/images/modelos/sw4/sw4Diamond/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/sw4/sw4Diamond/imagenModeloStore.jpg",
        miniatura: "/images/modelos/sw4/sw4Diamond/miniatura.png",
        interior: "/images/modelos/sw4/sw4Diamond/interior.jpg",
        exterior: "/images/modelos/sw4/sw4Diamond/exterior.jpg",
        catalogo: "/images/modelos/sw4/sw4Diamond/catalogo.pdf",
        consumo: "/images/modelos/sw4/sw4Diamond/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/sw4/sw4Diamond/interior/interior1.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior2.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior3.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior4.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior5.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior6.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior7.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior8.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior9.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/sw4/sw4Diamond/exterior/exterior1.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior2.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior3.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior4.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior5.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior6.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior7.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior8.jpg",
        ],
        imagenSalida: "/images/modelos/sw4/sw4Diamond/imagenSalida.jpg",
        versiones: [
          {
            version: "SW4 4X4 DIAMOND II 2.8 TDI 6 AT 7A",
            imagenModelo:
              "images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/blanco.png",
              },

              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/negro.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Faros delanteros Full-LED y diseño bitono",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de visión 360° y cargador inalámbrico para Smartphones**",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense*, Alerta de Punto Ciego (BSM) y Alerta de Tráfico Trasero (RCTA)",
              },
            ],
          },
        ],
      },
      {
        tipo: "SW4 GR-Sport",
        imagen: "/images/modelos/sw4/SW4GRSport/modelo.png",
        imagenBlur: "/images/modelos/sw4/SW4GRSport/blur.png",
        fondo: "/images/modelos/sw4/SW4GRSport/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/sw4/SW4GRSport/imagenModeloStore.jpg",
        miniatura: "/images/modelos/sw4/SW4GRSport/miniatura.png",
        interior: "/images/modelos/sw4/SW4GRSport/interior.jpg",
        exterior: "/images/modelos/sw4/SW4GRSport/exterior.jpg",
        catalogo: "/images/modelos/sw4/SW4GRSport/catalogo.pdf",
        consumo: "/images/modelos/sw4/SW4GRSport/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/sw4/SW4GRSport/interior/interior1.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior2.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior3.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior4.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior5.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior6.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior7.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior8.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior9.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior10.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior11.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior12.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior13.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior14.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/sw4/SW4GRSport/exterior/exterior1.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior2.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior3.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior4.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior5.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior6.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior7.jpg",
        ],
        imagenSalida: "/images/modelos/sw4/SW4GRSport/imagenSalida.jpg",
        versiones: [
          {
            version: "4X4 GR-S 2.8 TDI 6 AT 7A",
            imagenModelo:
              "/images/modelos/sw4/SW4GRSport/4X4GRS28TDI6AT7A/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/sw4/SW4GRSport/4X4GRS28TDI6AT7A/blanco.png",
              },

              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/sw4/SW4GRSport/4X4GRS28TDI6AT7A/negro.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Molduras y spoiler de diseño exclusivo GR",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Suspensión deportiva GR",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Tapizado de cuero combinado con Ultrasuede y detalles en rojo",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "SW4 DIAMOND",
    tipos: [
      {
        tipo: "SW4 DIAMOND",
        imagen: "/images/modelos/sw4/sw4Diamond/modelo.png",
        imagenBlur: "/images/modelos/sw4/sw4Diamond/blur.png",
        fondo: "/images/modelos/sw4/sw4Diamond/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/sw4/sw4Diamond/imagenModeloStore.jpg",
        miniatura: "/images/modelos/sw4/sw4Diamond/miniatura.png",
        interior: "/images/modelos/sw4/sw4Diamond/interior.jpg",
        exterior: "/images/modelos/sw4/sw4Diamond/exterior.jpg",
        catalogo: "/images/modelos/sw4/sw4Diamond/catalogo.pdf",
        consumo: "/images/modelos/sw4/sw4Diamond/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/sw4/sw4Diamond/interior/interior1.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior2.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior3.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior4.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior5.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior6.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior7.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior8.jpg",
          "/images/modelos/sw4/sw4Diamond/interior/interior9.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/sw4/sw4Diamond/exterior/exterior1.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior2.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior3.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior4.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior5.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior6.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior7.jpg",
          "/images/modelos/sw4/sw4Diamond/exterior/exterior8.jpg",
        ],
        imagenSalida: "/images/modelos/sw4/sw4Diamond/imagenSalida.jpg",
        versiones: [
          {
            version: "SW4 4X4 DIAMOND II 2.8 TDI 6 AT 7A",
            imagenModelo:
              "images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Blanco",
                imagen:
                  "images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/blanco.png",
              },

              {
                color: "rgb(226, 226, 226)",
                nombre: "Gris Plata Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/grisPlata.png",
              },
              {
                color: "rgb(194, 194, 194)",
                nombre: "Gris Oscuro Metalizado",
                imagen:
                  "/images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/grisOscuro.png",
              },
              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/sw4/sw4Diamond/SW44X4DIAMONDII28TDI6AT7A/negro.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Faros delanteros Full-LED y diseño bitono",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Sistema de visión 360° y cargador inalámbrico para Smartphones**",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense*, Alerta de Punto Ciego (BSM) y Alerta de Tráfico Trasero (RCTA)",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "SW4 GR-Sport",
    tipos: [
      {
        tipo: "SW4 GR-Sport",
        imagen: "/images/modelos/sw4/SW4GRSport/modelo.png",
        imagenBlur: "/images/modelos/sw4/SW4GRSport/blur.png",
        fondo: "/images/modelos/sw4/SW4GRSport/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/sw4/SW4GRSport/imagenModeloStore.jpg",
        miniatura: "/images/modelos/sw4/SW4GRSport/miniatura.png",
        interior: "/images/modelos/sw4/SW4GRSport/interior.jpg",
        exterior: "/images/modelos/sw4/SW4GRSport/exterior.jpg",
        catalogo: "/images/modelos/sw4/SW4GRSport/catalogo.pdf",
        consumo: "/images/modelos/sw4/SW4GRSport/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/sw4/SW4GRSport/interior/interior1.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior2.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior3.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior4.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior5.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior6.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior7.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior8.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior9.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior10.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior11.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior12.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior13.jpg",
          "/images/modelos/sw4/SW4GRSport/interior/interior14.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/sw4/SW4GRSport/exterior/exterior1.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior2.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior3.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior4.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior5.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior6.jpg",
          "/images/modelos/sw4/SW4GRSport/exterior/exterior7.jpg",
        ],
        imagenSalida: "/images/modelos/sw4/SW4GRSport/imagenSalida.jpg",
        versiones: [
          {
            version: "4X4 GR-S 2.8 TDI 6 AT 7A",
            imagenModelo:
              "/images/modelos/sw4/SW4GRSport/4X4GRS28TDI6AT7A/blanco.png",
            colores: [
              {
                color: "rgb(255, 246, 231)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/sw4/SW4GRSport/4X4GRS28TDI6AT7A/blanco.png",
              },

              {
                color: "rgb(0, 0, 0)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/sw4/SW4GRSport/4X4GRS28TDI6AT7A/negro.png",
              },
            ],
            datos: [
              {
                caracteristica: "Diseño - ",
                descripcion: "Molduras y spoiler de diseño exclusivo GR",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Suspensión deportiva GR",
              },
              {
                caracteristica: "Confort - ",
                descripcion:
                  "Tapizado de cuero combinado con Ultrasuede y detalles en rojo",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Land Cruiser Prado",
    tipos: [
      {
        tipo: "Land Cruiser Prado",
        imagen: "/images/modelos/landCruiserPrado/landCruiserPrado/modelo.png",
        imagenBlur:
          "/images/modelos/landCruiserPrado/landCruiserPrado/blur.png",
        fondo: "/images/modelos/landCruiserPrado/landCruiserPrado/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/landCruiserPrado/landCruiserPrado/imagenModeloStore.jpg",
        miniatura:
          "/images/modelos/landCruiserPrado/landCruiserPrado/miniatura.png",
        interior:
          "/images/modelos/landCruiserPrado/landCruiserPrado/interior.jpg",
        exterior:
          "/images/modelos/landCruiserPrado/landCruiserPrado/exterior.jpg",
        catalogo:
          "/images/modelos/landCruiserPrado/landCruiserPrado/catalogo.pdf",
        consumo:
          "/images/modelos/landCruiserPrado/landCruiserPrado/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/landCruiserPrado/landCruiserPrado/interior/interior1.jpg",
          "/images/modelos/landCruiserPrado/landCruiserPrado/interior/interior2.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/landCruiserPrado/landCruiserPrado/exterior/exterior1.jpg",
          "/images/modelos/landCruiserPrado/landCruiserPrado/exterior/exterior2.jpg",
        ],
        imagenSalida:
          "/images/modelos/landCruiserPrado/landCruiserPrado/imagenSalida.jpg",
        versiones: [
          {
            version: "Land Cruiser Prado 4.0 V6 A/T VX",
            imagenModelo:
              "/images/modelos/landCruiserPrado/landCruiserPrado/landCruiserPrado40V6ATVX/blanco.png",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/landCruiserPrado/landCruiserPrado/landCruiserPrado40V6ATVX/blanco.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/landCruiserPrado/landCruiserPrado/landCruiserPrado40V6ATVX/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/landCruiserPrado/landCruiserPrado/landCruiserPrado40V6ATVX/grisOscuro.png",
              },

              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/landCruiserPrado/landCruiserPrado/landCruiserPrado40V6ATVX/negro.png",
              },
              {
                color: "rgb(194, 193, 166)",
                nombre: "Bronze avant garde",
                imagen:
                  "/images/modelos/landCruiserPrado/landCruiserPrado/landCruiserPrado40V6ATVX/bronce.png",
              },
            ],
            datos: [
              {
                caracteristica: "Potencia - ",
                descripcion: "4.0 lts V6 275CV y 381 Nm de torque",
              },
              {
                caracteristica: "Performance - ",
                descripcion: "Sistema de suspensión Kinetic Dynamic KDSS.",
              },
              {
                caracteristica: "Seguridad - ",
                descripcion:
                  "Toyota Safety Sense: Sistema de Pre-colisión frontal (PCS)*, Sistema de alerta de cambio de carril (LDA)*, Luces Altas Automáticas (AHB)* y Control de velocidad crucero adaptativo (ACC)*",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Land Cruiser 300 VX",
    tipos: [
      {
        tipo: "Land Cruiser 300 VX",
        imagen: "/images/modelos/landCruiser300VX/landCruiser300VX/modelo.png",
        imagenBlur:
          "/images/modelos/landCruiser300VX/landCruiser300VX/blur.png",
        fondo: "/images/modelos/landCruiser300VX/landCruiser300VX/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/landCruiser300VX/landCruiser300VX/imagenModeloStore.jpg",
        miniatura:
          "/images/modelos/landCruiser300VX/landCruiser300VX/miniatura.png",
        interior:
          "/images/modelos/landCruiser300VX/landCruiser300VX/interior.jpg",
        exterior:
          "/images/modelos/landCruiser300VX/landCruiser300VX/exterior.jpg",
        catalogo:
          "/images/modelos/landCruiser300VX/landCruiser300VX/catalogo.pdf",
        consumo:
          "/images/modelos/landCruiser300VX/landCruiser300VX/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/landCruiser300VX/landCruiser300VX/interior/interior1.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/interior/interior2.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/interior/interior3.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/interior/interior4.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/interior/interior5.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/interior/interior6.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/landCruiser300VX/landCruiser300VX/exterior/exterior1.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/exterior/exterior2.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/exterior/exterior3.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300VX/exterior/exterior4.jpg",
        ],
        imagenSalida:
          "/images/modelos/landCruiser300VX/landCruiser300VX/imagenSalida.jpg",
        versiones: [
          {
            version: "Land Cruiser 300 VX 3.3 V6 TD A/T",
            imagenModelo:
              "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/blanco.png",
            colores: [
              {
                color: "rgb(245, 248, 240)",
                nombre: "Blanco",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/blanco.png",
              },
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/blancoPerlado.png",
              },
              {
                color: "rgb(195, 195, 192)",
                nombre: "Gris Plata",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/grisPlata.png",
              },
              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/grisOscuro.png",
              },

              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/negro.png",
              },
              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro Mica",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/negroMica.png",
              },
              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/rojo.png",
              },
              {
                color: "rgb(194, 193, 166)",
                nombre: "Bronze avant garde",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/bronce.png",
              },
              {
                color: "rgb(36, 65, 251)",
                nombre: "Azul Metalizado",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300VX/landCruiser300VX33V6TDAT/azulMetalizado.png",
              },
            ],
            datos: [
              {
                caracteristica: "Potencia - ",
                descripcion:
                  "3.3 lts V6 Twin turbo Diesel 304 CV y 700 Nm de torque",
              },
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Monitor Multi-Terreno 3D y Bloqueo Diferencial Trasero",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio Premium JBL® con pantalla táctil de 12.3’ y 14 parlantes",
              },
            ],
          },
        ],
      },
      {
        tipo: "Land Cruiser 300 GR-S",
        imagen: "/images/modelos/landCruiser300VX/landCruiser300GRS/modelo.png",
        imagenBlur:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/blur.png",
        fondo: "/images/modelos/landCruiser300VX/landCruiser300GRS/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/imagenModeloStore.jpg",
        miniatura:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/miniatura.png",
        interior:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/interior.jpg",
        exterior:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/exterior.jpg",
        catalogo:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/catalogo.pdf",
        consumo:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/landCruiser300VX/landCruiser300GRS/interior/interior1.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300GRS/interior/interior2.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/landCruiser300VX/landCruiser300GRS/exterior/exterior1.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300GRS/exterior/exterior2.jpg",
        ],
        imagenSalida:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/imagenSalida.jpg",
        versiones: [
          {
            version: "Land Cruiser 300 GR-S",
            imagenModelo:
              "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/blancoPerlado.png",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/blancoPerlado.png",
              },

              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/grisOscuro.png",
              },

              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/negro.png",
              },

              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Potencia - ",
                descripcion:
                  "3.3 lts V6 Twin turbo Diesel 304 CV y 700 Nm de torque",
              },
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Monitor Multi-Terreno 3D y Bloqueo Diferencial Trasero",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio Premium JBL® con pantalla táctil de 12.3’ y 14 parlantes",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    nombre: "Land Cruiser 300 GR-S",
    tipos: [
      {
        tipo: "Land Cruiser 300 GR-S",
        imagen: "/images/modelos/landCruiser300VX/landCruiser300GRS/modelo.png",
        imagenBlur:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/blur.png",
        fondo: "/images/modelos/landCruiser300VX/landCruiser300GRS/fondo.jpg",
        sloganStore: "Subite sin salir de casa",
        bajadaStore: "Una nueva experiencia digital para acceder a tu vehículo",
        imagenModeloStore:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/imagenModeloStore.jpg",
        miniatura:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/miniatura.png",
        interior:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/interior.jpg",
        exterior:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/exterior.jpg",
        catalogo:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/catalogo.pdf",
        consumo:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/consumo.pdf",
        imagenesInterior: [
          "/images/modelos/landCruiser300VX/landCruiser300GRS/interior/interior1.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300GRS/interior/interior2.jpg",
        ],
        imagenesExterior: [
          "/images/modelos/landCruiser300VX/landCruiser300GRS/exterior/exterior1.jpg",
          "/images/modelos/landCruiser300VX/landCruiser300GRS/exterior/exterior2.jpg",
        ],
        imagenSalida:
          "/images/modelos/landCruiser300VX/landCruiser300GRS/imagenSalida.jpg",
        versiones: [
          {
            version: "Land Cruiser 300 GR-S",
            imagenModelo:
              "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/blancoPerlado.png",
            colores: [
              {
                color: "rgb(237, 237, 237)",
                nombre: "Blanco Perlado",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/blancoPerlado.png",
              },

              {
                color: "rgb(108, 109, 112)",
                nombre: "Gris Oscuro",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/grisOscuro.png",
              },

              {
                color: "rgb(44, 45, 50)",
                nombre: "Negro",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/negro.png",
              },

              {
                color: "rgb(215, 34, 49",
                nombre: "Rojo Metalizado",
                imagen:
                  "/images/modelos/landCruiser300VX/landCruiser300GRS/landCruiser300GRS/rojo.png",
              },
            ],
            datos: [
              {
                caracteristica: "Potencia - ",
                descripcion:
                  "3.3 lts V6 Twin turbo Diesel 304 CV y 700 Nm de torque",
              },
              {
                caracteristica: "Performance - ",
                descripcion:
                  "Monitor Multi-Terreno 3D y Bloqueo Diferencial Trasero",
              },
              {
                caracteristica: "Tecnología - ",
                descripcion:
                  "Audio Premium JBL® con pantalla táctil de 12.3’ y 14 parlantes",
              },
            ],
          },
        ],
      },
    ],
  },
];
