import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

export default function InteriorExterior({ images }) {
  return (
    <Row>
      <Col xs={12} className="m-0 p-0">
        <Image src={images} alt="InteriorExterior" width="100%" />
      </Col>
    </Row>
  );
}
