import React from "react";

import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";

export default function EnlargedCard({ images, modeloSeleccionado }) {
  const handleModeloSeleccionado = (name, i) => {
    modeloSeleccionado(name, i);
    window.scrollTo(0, 0);
  };

  return (
    <Link to={images.url}>
      <Card
        className="expandCard cardHome"
        bg="light"
        border="white"
        style={{ width: "20rem" }}
        onClick={() => handleModeloSeleccionado(images.modelo, images.position)}
      >
        <Card.Body>
          <Card.Title>{images.modelo}</Card.Title>
          <Card.Img variant="top" src={images.img} />
          <Card.Text></Card.Text>
        </Card.Body>
      </Card>
    </Link>
  );
}
