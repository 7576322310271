import { useField } from "formik";
import React from "react";
import { Col, Row } from "react-bootstrap";

export default function CustomInput({ label, ...props }) {
  const [field, meta /* helpers */] = useField(props);

  //console.log(field, "field");
  //console.log(meta, "meta");

  return (
    <>
      <Row className="m-0 p-0 mt-2">
        <Col>
          <label>{label}</label>
          <input
            {...field}
            {...props}
            className={
              meta.touched && meta.error
                ? "input-error form-control"
                : "form-control"
            }
          />
        </Col>
      </Row>
      <Row className="m-0 p-0 mb-2">
        <Col>
          {meta.touched && meta.error && (
            <div className="errorMessage">{meta.error}</div>
          )}
        </Col>
      </Row>
    </>
  );
}
