import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import "bootstrap/dist/css/bootstrap.min.css";
import { BreakpointsProvider } from "react-with-breakpoints";

//Font Awesome Library
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faTrash,
  faEdit,
  faUniversity,
  faUserFriends,
  faFileInvoice,
  faCar,
  faHome,
  faList,
  faListAlt,
  faClipboardList,
  faSearch,
  faCalendarAlt,
  faMinus,
  faPlus,
  faWrench,
  faBook,
  faTimesCircle,
  faPencilAlt,
  faSave,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";

import UiContextProvider from "./contexts/UiContextProvider";

library.add(
  faBars,
  faTrash,
  faEdit,
  faUniversity,
  faUserFriends,
  faFileInvoice,
  faCar,
  faHome,
  faList,
  faListAlt,
  faClipboardList,
  faSearch,
  faCalendarAlt,
  faMinus,
  faPlus,
  faWrench,
  faBook,
  faTimesCircle,
  faPencilAlt,
  faSave,
  faEnvelope
);

ReactDOM.render(
  <React.StrictMode>
    <UiContextProvider>
      <BreakpointsProvider>
        <App />
      </BreakpointsProvider>
    </UiContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
