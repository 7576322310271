import React, { useState } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Layout from "./components/common/Layout";
import Financiacion from "./components/sections/Financiacion";
import Home from "./components/sections/Home";
import Modelos from "./components/sections/Modelos";
import ModeloSeleccionado from "./components/sections/ModeloSeleccionado";
import Posventa from "./components/sections/Posventa";
import Contacto from "./components/sections/contacto/Contacto";
import ToyotaApp from "./components/sections/ToyotaApp";

function App() {
  const [modeloURL, setModeloURL] = useState("");
  const [modelo, setModelo] = useState("");
  const [posicion, setPosicion] = useState(0);

  const modeloSeleccionado = (name, i) => {
    setPosicion(i);
    setModelo(name);
    setModeloURL(
      name
        .toLowerCase()
        .replaceAll(" ", "")
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
    );
  };

  return (
    <Router>
      <Layout>
        <Route exact path="/">
          <Home modeloSeleccionado={modeloSeleccionado} />
        </Route>
        <Route exact path="/modelos">
          <Modelos modeloSeleccionado={modeloSeleccionado} />
        </Route>
        <Route exact path="/posventa">
          <Posventa />
        </Route>
        <Route exact path="/financiacion">
          <Financiacion />
        </Route>
        <Route exact path="/contacto">
          <Contacto />
        </Route>
        <Route exact path={modeloURL === "" ? "/nada" : "/" + modeloURL}>
          <ModeloSeleccionado modeloSeleccionado={modelo} posicion={posicion} />
        </Route>
        <Route exact path="/toyota_app">
          <ToyotaApp />
        </Route>
      </Layout>
    </Router>
  );
}

export default App;
