import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

import { modelos } from "../data/ModelosData";
import { Link } from "react-router-dom";

export default function Modelos({ modeloSeleccionado }) {
  const handleModeloSeleccionado = (name, i) => {
    modeloSeleccionado(name, i);
    window.scrollTo(0, 0);
  };

  const renderAutosyMinivans = () => {
    let test = modelos.map((detalle, i) => {
      while (i < 7) {
        return (
          <Col className="text-center mb-1 showButton" md={4} lg={3} key={i}>
            <Link
              to={detalle.nombre
                .toLowerCase()
                .replaceAll(" ", "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}
            >
              <h3
                className="mt-4"
                style={{ cursor: "pointer", height: "50px" }}
                onClick={() => handleModeloSeleccionado(detalle.nombre, i)}
              >
                <b>{detalle.nombre}</b>
              </h3>
              <Col className="m-0 p-0">
                <Image src={detalle.logo} />
              </Col>
            </Link>
            <Link
              to={detalle.nombre
                .toLowerCase()
                .replaceAll(" ", "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}
            >
              <Image
                className="mb-3 mt-3"
                style={{ cursor: "pointer" }}
                width="220px"
                src={detalle.imagen}
                onClick={() => handleModeloSeleccionado(detalle.nombre, i)}
              />
            </Link>
            <Row className="m-0 p-0">
              <Col className="m-0 p-0 hideButtons">
                <Col xs={12} className="m-0 mb-1 p-0">
                  <Link
                    to={detalle.nombre
                      .toLowerCase()
                      .replaceAll(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}
                  >
                    <Button
                      size="sm"
                      variant="outline-dark"
                      style={{ borderRadius: "15px" }}
                    >
                      <span
                        className="px-2"
                        onClick={() =>
                          handleModeloSeleccionado(detalle.nombre, i)
                        }
                      >
                        Ver Modelo
                      </span>
                    </Button>
                  </Link>
                </Col>
                {detalle.nombre !== "GR Yaris" && (
                  <Col xs={12}>
                    <a
                      href="https://store.autoprana.com.ar/vtf/"
                      target="_self"
                    >
                      <Button
                        size="sm"
                        variant="outline-dark"
                        style={{ borderRadius: "15px" }}
                      >
                        Cotiza Online
                      </Button>
                    </a>
                  </Col>
                )}
              </Col>
            </Row>
          </Col>
        );
      }
    });
    return test;
  };
  const renderPickupsyComerciales = () => {
    let test = modelos.map((detalle, i) => {
      while (i > 6 && i < 11) {
        return (
          <Col className="text-center mb-1 showButton" md={4} lg={3} key={i}>
            <Link
              to={detalle.nombre
                .toLowerCase()
                .replaceAll(" ", "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}
            >
              <h3
                className="mt-4"
                style={{ cursor: "pointer", height: "50px" }}
                onClick={() => handleModeloSeleccionado(detalle.nombre, i)}
              >
                <b>{detalle.nombre}</b>
              </h3>
              <Col className="m-0 p-0">
                <Image src={detalle.logo} />
              </Col>
            </Link>
            <Link
              to={detalle.nombre
                .toLowerCase()
                .replaceAll(" ", "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}
            >
              <Image
                className="mb-3 mt-3"
                style={{ cursor: "pointer" }}
                width="220px"
                src={detalle.imagen}
                onClick={() => handleModeloSeleccionado(detalle.nombre, i)}
              />
            </Link>
            <Row className="m-0 p-0">
              <Col className="m-0 p-0 hideButtons">
                <Col xs={12} className="m-0 mb-1 p-0">
                  <Link
                    to={detalle.nombre
                      .toLowerCase()
                      .replaceAll(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}
                  >
                    <Button
                      size="sm"
                      variant="outline-dark"
                      style={{ borderRadius: "15px" }}
                    >
                      <span
                        className="px-2"
                        onClick={() =>
                          handleModeloSeleccionado(detalle.nombre, i)
                        }
                      >
                        Ver Modelo
                      </span>
                    </Button>
                  </Link>
                </Col>

                {detalle.nombre !== "Hiace Furgón" &&
                  detalle.nombre !== "Hiace Commuter" &&
                  detalle.nombre !== "Hiace Wagon" && (
                    <Col xs={12}>
                      <a
                        href="https://store.autoprana.com.ar/vtf/"
                        target="_self"
                      >
                        <Button
                          size="sm"
                          variant="outline-dark"
                          style={{ borderRadius: "15px" }}
                        >
                          Cotiza Online
                        </Button>
                      </a>
                    </Col>
                  )}
              </Col>
            </Row>
          </Col>
        );
      }
    });
    return test;
  };
  const renderSUVyCROSSOVERS = () => {
    let modelosPorTipo = modelos.map((detalle, i) => {
      while (i > 10) {
        return (
          <Col className="text-center mb-1 showButton" md={4} lg={3} key={i}>
            <Link
              to={detalle.nombre
                .toLowerCase()
                .replaceAll(" ", "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}
            >
              <h3
                className="mt-4"
                style={{ cursor: "pointer", height: "50px" }}
                onClick={() => handleModeloSeleccionado(detalle.nombre, i)}
              >
                <b>{detalle.nombre}</b>
              </h3>
              <Col className="m-0 p-0">
                <Image src={detalle.logo} />
              </Col>
            </Link>
            <Link
              to={detalle.nombre
                .toLowerCase()
                .replaceAll(" ", "")
                .normalize("NFD")
                .replace(/[\u0300-\u036f]/g, "")}
            >
              <Image
                className="mb-3 mt-3"
                style={{ cursor: "pointer" }}
                width="220px"
                src={detalle.imagen}
                onClick={() => handleModeloSeleccionado(detalle.nombre, i)}
              />
            </Link>
            <Row className="m-0 p-0">
              <Col className="m-0 p-0 hideButtons">
                <Col xs={12} className="m-0 mb-1 p-0">
                  <Link
                    to={detalle.nombre
                      .toLowerCase()
                      .replaceAll(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}
                  >
                    <Button
                      size="sm"
                      variant="outline-dark"
                      style={{ borderRadius: "15px" }}
                    >
                      <span
                        className="px-2"
                        onClick={() =>
                          handleModeloSeleccionado(detalle.nombre, i)
                        }
                      >
                        Ver Modelo
                      </span>
                    </Button>
                  </Link>
                </Col>
                {detalle.nombre !== "C-HR" &&
                  detalle.nombre !== "RAV4" &&
                  detalle.nombre !== "SW4 DIAMOND" &&
                  detalle.nombre !== "Land Cruiser Prado" &&
                  detalle.nombre !== "Land Cruiser 300 VX" &&
                  detalle.nombre !== "Land Cruiser 300 GR-S" && (
                    <Col xs={12}>
                      <a
                        href="https://store.autoprana.com.ar/vtf/"
                        target="_self"
                      >
                        <Button
                          size="sm"
                          variant="outline-dark"
                          style={{ borderRadius: "15px" }}
                        >
                          Cotiza Online
                        </Button>
                      </a>
                    </Col>
                  )}
              </Col>
            </Row>
          </Col>
        );
      }
    });
    return modelosPorTipo;
  };

  return (
    <>
      <Col
        md={{ span: 10, offset: 1 }}
        lg={{ span: 8, offset: 2 }}
        style={{ marginTop: "50px" }}
      >
        <h4 className="mb-4">Autos y Minivans</h4>

        <Row className="m-0 p-0 justify-content-center">
          {renderAutosyMinivans()}
        </Row>
      </Col>
      <hr className="my-5" width="60%" />
      <Col
        md={{ span: 10, offset: 1 }}
        lg={{ span: 8, offset: 2 }}
        style={{ marginTop: "50px" }}
      >
        <h4 className="mb-4">Pickups y Comerciales</h4>

        <Row className="m-0 p-0 justify-content-center">
          {renderPickupsyComerciales()}
        </Row>
      </Col>
      <hr className="my-5" width="60%" />
      <Col
        md={{ span: 10, offset: 1 }}
        lg={{ span: 8, offset: 2 }}
        style={{ marginTop: "50px" }}
      >
        <h4 className="mb-4">SUV y CROSSOVERS</h4>

        <Row className="m-0 p-0 justify-content-center">
          {renderSUVyCROSSOVERS()}
        </Row>
      </Col>
      <hr className="my-5" width="60%" />
    </>
  );
}

/*  {modelos.map((detalle, i) => (
        <>
          <Col
            md={{ span: 8, offset: 2 }}
            style={{ marginTop: "50px" }}
            key={i}
          >
            <h4 className="mb-4">{detalle.tipo}</h4>
            <Row className="m-0 p-0 justify-content-center">
              {detalle.modelos.map((auto, i) => (
                <Col className="text-center mb-1 showButton" md={3} key={i}>
                  <Link
                    to={auto.nombre
                      .toLowerCase()
                      .replaceAll(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}
                  >
                    <h3
                      className="mt-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleModeloSeleccionado(auto.nombre, i)}
                    >
                      <b>{auto.nombre}</b>
                    </h3>
                    <Col className="m-0 p-0">
                      <Image src={auto.logo} />
                    </Col>
                  </Link>
                  <Link
                    to={auto.nombre
                      .toLowerCase()
                      .replaceAll(" ", "")
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")}
                  >
                    <Image
                      className="mb-3 mt-3"
                      style={{ cursor: "pointer" }}
                      width="220px"
                      src={auto.imagen}
                      onClick={() => handleModeloSeleccionado(auto.nombre, i)}
                    />
                  </Link>
                  <Row className="m-0 p-0">
                    <Col className="m-0 p-0 hideButtons">
                      <Col xs={12} className="m-0 mb-1 p-0">
                        <Link
                          to={auto.nombre
                            .toLowerCase()
                            .replaceAll(" ", "")
                            .normalize("NFD")
                            .replace(/[\u0300-\u036f]/g, "")}
                        >
                          <Button
                            size="sm"
                            variant="outline-dark"
                            style={{ borderRadius: "15px" }}
                          >
                            <span
                              className="px-2"
                              onClick={() =>
                                handleModeloSeleccionado(auto.nombre, i)
                              }
                            >
                              Ver Modelo
                            </span>
                          </Button>
                        </Link>
                      </Col>
                      <Col xs={12}>
                        <a
                          href="https://store.autoprana.com.ar/vtf/"
                          target="_self"
                        >
                          <Button
                            size="sm"
                            variant="outline-dark"
                            style={{ borderRadius: "15px" }}
                          >
                            Cotiza Online
                          </Button>
                        </a>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              ))}
            </Row>
          </Col>
          <hr className="my-5" width="60%" />
        </>
      ))}
 */
