import React from "react"; //imr
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SideBar from "../common/SideBar";
import NavigationBar from "../common/NavigationBar";

import Footer from "../common/Footer";

export default function Layout({ children }) {
  return (
    <>
      <Container className="m-0 p-0" fluid>
        <Row className="m-0 p-0">
          <Col className="m-0 p-0">
            <NavigationBar />
            {children}
          </Col>
        </Row>
        <Footer />
      </Container>
      <SideBar />
    </>
  );
}
