import React from "react";

import Image from "react-bootstrap/Image";

export default function SliderCards({ activeItemIndex, slide, i }) {
  return (
    <a href={slide.link}>
      <Image
        style={{
          borderRadius: "10px",
          opacity: i !== activeItemIndex ? 0.5 : 1,
        }}
        className="d-block w-100"
        src={slide && slide.img}
        alt="First slide"
        key={i}
      />
    </a>
  );
}
