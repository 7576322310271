import React from "react";

import { Button, Col, Row } from "react-bootstrap";
import { Formik, Form } from "formik";

import useContacto from "./hooks/useContacto";
import CustomInput from "../../common/CustomInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useLocation } from "react-router-dom";

export default function Contacto() {
  let location = useLocation();

  const tipoConsulta = location.tipoConsulta;

  const { advanceSchema, onSubmit, numberRegex, justletterRegex } = useContacto(
    { tipoConsulta }
  );

  return (
    <>
      <Row className="m-0 p-0 mt-5">
        <Col xs={12} xl={{ span: "8", offset: "2" }} className="mb-5 mt-3">
          <Row
            className="m-0 p-0"
            style={{ borderRadius: "5px", backgroundColor: "lightGray" }}
          >
            <Col
              xs={12}
              className="text-center pb-1"
              style={{
                backgroundColor: "darkgray",
                fontSize: "1.5rem",
                borderRadius: "5px",
                color: "black",
              }}
            >
              {tipoConsulta}
            </Col>
          </Row>

          <Formik
            initialValues={{
              nombre: "",
              email: "",
              telefono: "",
              mensaje: "",
            }}
            validationSchema={advanceSchema}
            onSubmit={onSubmit}
          >
            {({ isSubmitting, values, setFieldValue }) => (
              <Form autoComplete="off">
                <Row className="m-0 p-0 mt-3">
                  <Col xs={12} lg={{ span: "8", offset: "2" }} className="mb-4">
                    <CustomInput
                      label={
                        <span>
                          <FontAwesomeIcon icon="user" /> &nbsp; Nombre y
                          apellido
                        </span>
                      }
                      name="nombre"
                      type="text"
                      placeholder="Ingrese nombre y apellido"
                      value={values.nombre}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (
                          !value ||
                          justletterRegex.test(value.slice(-1).toString())
                        ) {
                          setFieldValue("nombre", value);
                        }
                      }}
                    />
                    <CustomInput
                      label={
                        <span>
                          <FontAwesomeIcon icon="phone" /> &nbsp; Telefono
                        </span>
                      }
                      name="telefono"
                      type="text"
                      placeholder="solo numeros"
                      value={values.telefono}
                      onChange={(e) => {
                        e.preventDefault();
                        const { value } = e.target;
                        if (!value || numberRegex.test(value.toString())) {
                          setFieldValue("telefono", value);
                        }
                      }}
                    />
                    <CustomInput
                      label={
                        <span>
                          <FontAwesomeIcon icon="at" /> &nbsp; Email
                        </span>
                      }
                      name="email"
                      type="text"
                      placeholder="cliente@email.com"
                      value={values.correo}
                    />
                    <CustomInput
                      label={
                        <span>
                          <FontAwesomeIcon icon="comment" /> &nbsp; Mensaje
                        </span>
                      }
                      name="mensaje"
                      type="text"
                      placeholder="ingrese su mensaje"
                      value={values.mensaje}
                      maxLength="300"
                    />
                  </Col>
                </Row>
                <Row className="m-0 p-0">
                  <Col xs={12} className="text-center">
                    <Button
                      type="submit"
                      className="btn btn-primary"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Procesando..." : "Enviar"}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    </>
  );
}
