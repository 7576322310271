import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SidebarHeader,
  SidebarContent,
  SidebarFooter,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

import { uiContext } from "../../contexts/UiContextProvider";

//Breakpoints
import { HideAt } from "react-with-breakpoints";

import { Link, NavLink } from "react-router-dom";

export default function SideBar() {
  const uiManager = useContext(uiContext);

  let height = window.innerHeight;
  let footerHeight = (20 * height) / 100;

  return (
    <ProSidebar
      collapsed={uiManager.sideBar.collapsed}
      breakPoint={"md"}
      toggled={uiManager.sideBar.toggled}
      onToggle={uiManager.sideBar.hide}
      rtl={true}
    >
      <Link onClick={uiManager.sideBar.hide}>
        <SidebarHeader
          style={{ height: "72px", overflow: "hidden", color: "black" }}
        >
          <Row className="m-0 p-0 text-right">
            <Col style={{ paddingTop: "25px" }}>
              <FontAwesomeIcon icon="times-circle" />
              <span> Cerrar</span>
            </Col>
          </Row>
        </SidebarHeader>
      </Link>

      <SidebarContent>
        <Menu className="text-right">
          <MenuItem>
            <NavLink
              activeClassName="active-menu-item"
              exact
              to="/modelos"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Modelos
            </NavLink>
          </MenuItem>
          <MenuItem>
            <a href="https://store.autoprana.com.ar/vtf/">Reserva tu 0km</a>
          </MenuItem>
          <MenuItem>
            <a href="https://store.autoprana.com.ar/#/usados">Usados</a>
          </MenuItem>
          {/*   <MenuItem>
            <a href="https://store.autoprana.com.ar/cotizador/">
              Cotizá tu usado
            </a>
          </MenuItem> */}
          <hr></hr>
          <MenuItem>
            <NavLink
              activeClassName="active-menu-item"
              exact
              to="/posventa"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Posventa
            </NavLink>
          </MenuItem>
          <MenuItem>
            <NavLink
              activeClassName="active-menu-item"
              exact
              to="financiacion"
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Financiación
            </NavLink>
          </MenuItem>
          <hr></hr>
          <MenuItem
            icon={
              <Image
                width="25px"
                src="/images/logos/kinto-mobility-service.svg"
              />
            }
          >
            <a href="https://store.autoprana.com.ar/#/kinto">Kinto</a>
          </MenuItem>
          <MenuItem icon={<Image src="/images/logos/clubToyota.png" />}>
            <a
              href="https://www.clubtoyota.com.ar/"
              target="_blank"
              rel="noreferrer"
            >
              Club Toyota
            </a>
          </MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon="envelope" />}>
            <NavLink
              to={{
                pathname: "/contacto",
                tipoConsulta: "Consulta general",
              }}
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Contacto
            </NavLink>
          </MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon="car" />}>
            <NavLink
              to={{
                pathname: "/contacto",
                tipoConsulta: "Test Drive",
              }}
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Test Drive
            </NavLink>
          </MenuItem>
          <MenuItem icon={<FontAwesomeIcon icon="book" />}>
            <NavLink
              to={{
                pathname: "/contacto",
                tipoConsulta: "Libro de quejas",
              }}
              onClick={uiManager.sideBar.hide}
              style={{ color: "black" }}
            >
              Libro de quejas
            </NavLink>
          </MenuItem>
          <MenuItem icon={<Image src="/images/logos/grMini.png" />}>
            <a
              href="https://www.toyotagazooracing.com.ar/"
              target="_blank"
              rel="noreferrer"
            >
              Gazoo Racing
            </a>
          </MenuItem>
          <MenuItem icon={<Image src="/images/logos/hybridMini.png" />}>
            <a
              href="https://www.toyota.com.ar/descubri/electrificacion/hibridos"
              target="_blank"
              rel="noreferrer"
            >
              Híbridos
            </a>
          </MenuItem>
          <MenuItem icon={<Image src="/images/logos/sense.png" />}>
            <a
              href="https://www.toyota.com.ar/descubri/toyota-safety-sense"
              target="_blank"
              rel="noreferrer"
            >
              Toyota Safety Sense
            </a>
          </MenuItem>
          <MenuItem
            icon={
              <Image
                src="/images/logos/toyotagreen.png"
                style={{ width: "25px" }}
              />
            }
          >
            <a
              href="https://store.autoprana.com.ar/politicaAmbiental.php"
              target="_blank"
              rel="noreferrer"
            >
              SGA
            </a>
          </MenuItem>
          <MenuItem
            icon={
              <Image src="/images/logos/gri.png" style={{ width: "25px" }} />
            }
          >
            <a
              href="/images/homeNews/sustentabilidad.pdf"
              target="_blank"
              rel="noreferrer"
            >
              Sustentabilidad
            </a>
          </MenuItem>
        </Menu>
        <HideAt breakpoint="mediumAndAbove"></HideAt>
      </SidebarContent>
      <SidebarFooter
        className="dynamicFooter"
        style={{ backgroundColor: "lightGrey", height: footerHeight }}
      ></SidebarFooter>
    </ProSidebar>
  );
}
