import React, { useState, useEffect } from "react";

import ItemsCarousel from "react-items-carousel";
import { Button } from "antd";

import EnlargedCard from "../common/EnlargedCard";
import CardFeatures from "../common/CardFeatures";
import SliderCards from "../common/SliderCards";
import InteriorExterior from "./InteriorExterior";

export default function Slider({
  images,
  objectToRender,
  infiniteLoop,
  handleVersionSeleccionada,
  modeloSeleccionado,
}) {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  // eslint-disable-next-line no-unused-vars
  const [slides, setSlides] = useState(images);

  const [firstAndLastGutter, setFirstAndLastGutter] = useState(false);
  const [cantTarjetas, setCantTarjetas] = useState(4);
  const [cantTarjetasSecundario, setCantTarjetasSecundario] = useState(4);
  const [cantTarjetasCardFeatures, setCantTarjetasCardFeatures] = useState(3);
  const [cantTarjetasIntOrExt, setCantTarjetasIntOrExt] = useState(1);

  const size = useWindowSize();

  useEffect(() => {
    setSlides(images);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, images);

  function useWindowSize() {
    const isClient = typeof window === "object";

    function getSize() {
      return {
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined,
      };
    }

    const [windowSize, setWindowSize] = useState(getSize);

    useEffect(() => {
      if (!isClient) {
        return false;
      }

      function handleResize() {
        setWindowSize(getSize());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return windowSize;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (size.width < 450) {
      setCantTarjetas(1);
      setCantTarjetasSecundario(1);
      setCantTarjetasCardFeatures(1);
      setCantTarjetasIntOrExt(1);
      setFirstAndLastGutter(false);
    } else {
      if (size.width < 700) {
        setCantTarjetas(1);
        setCantTarjetasSecundario(2);
        setCantTarjetasCardFeatures(1);
        setCantTarjetasIntOrExt(1);
        setFirstAndLastGutter(false);
      } else {
        if (size.width < 800) {
          setCantTarjetas(1);
          setCantTarjetasSecundario(2);
          setCantTarjetasCardFeatures(1);
          setCantTarjetasIntOrExt(1);
          setFirstAndLastGutter(false);
        } else {
          if (size.width < 1000) {
            setCantTarjetas(1);
            setCantTarjetasSecundario(3);
            setCantTarjetasCardFeatures(1);
            setCantTarjetasIntOrExt(1);
            setFirstAndLastGutter(false);
          } else {
            setCantTarjetas(1.65);
            setCantTarjetasSecundario(4);
            setCantTarjetasCardFeatures(3);
            setCantTarjetasIntOrExt(1);
            setFirstAndLastGutter(true);
          }
        }
      }
    }
  });

  const sliderModelo = () => {
    var carousel = slides.map((slide, i) => {
      return (
        <>
          {objectToRender === "image" && (
            <SliderCards
              activeItemIndex={activeItemIndex}
              slide={slide}
              i={i}
            />
          )}
          {objectToRender === "expandedCard" && (
            <EnlargedCard
              images={slide}
              modeloSeleccionado={modeloSeleccionado}
            />
          )}
          {objectToRender === "cardFeatures" && (
            <>
              <CardFeatures
                slide={slide}
                i={i}
                handleVersionSeleccionada={handleVersionSeleccionada}
              />
            </>
          )}
          {objectToRender === "interiorExterior" && (
            <>
              <InteriorExterior images={slide} />
            </>
          )}
        </>
      );
    });

    return carousel;
  };

  const [bkgChevron, setBkgChevron] = useState("fondo-chevron-rojo");

  function changeBckChevron() {
    if (bkgChevron === "fondo-chevron-rojo") {
      setBkgChevron("fondo-chevron-negro");
    } else {
      setBkgChevron(() => "fondo-chevron-rojo");
    }
  }

  useEffect(() => {
    const timer = window.setInterval(() => {
      changeBckChevron();
    }, 1000);
    return () => {
      window.clearInterval(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bkgChevron]);

  const [timer, setTimer] = useState(0);
  const [timer2, setTimer2] = useState(0);

  const handleCard = (futureIndex) => {
    window.clearInterval(timer);
    setTimer(0);

    if (futureIndex > slides.length - 1) {
      setActiveItemIndex(0);
    } else if (futureIndex < 0) {
      setActiveItemIndex(slides.length - 1);
    } else {
      setActiveItemIndex(futureIndex);
    }

    window.clearTimeout(timer2);
    setTimer2(window.setTimeout(changeCard, 6000));
  };

  const changeCard = () => {
    if (objectToRender === "image") {
      setTimer(
        window.setInterval(() => {
          setActiveItemIndex((state) => {
            if (state < slides.length - 1) {
              return state + 1;
            } else {
              return 0;
            }
          });
        }, 6000)
      );
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => changeCard(), []);

  return (
    <div
      className="lateral"
      style={
        objectToRender === "cardFeatures"
          ? { padding: `0 0px`, marginTop: "20px" }
          : objectToRender === "interiorExterior"
          ? { marginTop: "0px" }
          : { padding: `0 ${chevronWidth}px`, marginTop: "20px" }
      }
    >
      <ItemsCarousel
        infiniteLoop={infiniteLoop}
        activePosition={"center"}
        disableSwipe={false}
        alwaysShowChevrons={false}
        slidesToScroll={1}
        showSlither={false}
        firstAndLastGutter={firstAndLastGutter}
        requestToChangeActive={handleCard}
        activeItemIndex={activeItemIndex}
        numberOfCards={
          objectToRender === "image"
            ? cantTarjetas
            : objectToRender === "expandedCard"
            ? cantTarjetasSecundario
            : objectToRender === "cardFeatures"
            ? cantTarjetasCardFeatures
            : objectToRender === "interiorExterior" && cantTarjetasIntOrExt
        }
        gutter={30}
        leftChevron={
          <Button
            // eslint-disable-next-line no-useless-concat
            className={bkgChevron + " " + "btn btn-floating red chevronIzq "}
          >
            <i className="material-icons">chevron_left</i>
          </Button>
        }
        rightChevron={
          <Button
            // eslint-disable-next-line no-useless-concat
            className={bkgChevron + " " + "btn btn-floating red chevronDer "}
          >
            <i className="material-icons">chevron_right</i>
          </Button>
        }
        outsideChevron={true}
        chevronWidth={chevronWidth}
      >
        {sliderModelo()}
      </ItemsCarousel>
    </div>
  );
}
