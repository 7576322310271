import React from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ShowAt, HideAt } from "react-with-breakpoints";

export default function Financiacion() {
  /* const handleShowDecription = ()=>{

    }
    style={{  visibility : description}} */

  const screenSize = {
    MOBILE: {
      titleMarginTop: "0",
      position: "relative",
      titleFontSize: "28px",
      titleAlignment: "text-left",
    },
    DESKTOP: {
      titleMarginTop: "130px",
      descriptionMarginTop: "20px",
      position: "absolute",
      bottom: 0,
      titleFontSize: "3.5rem",
      titleAlignment: "text-center",
    },
  };

  const cardData = [
    {
      title: "Plan de Ahorro",
      description: `Invertí en tu Toyota, hay un plan para vos.`,
      action: "Comprar",
      url: "https://toyotaplan.com.ar/",
    },
    {
      title: "Financiá tu Toyota",
      description: `Elegí la financiación que más te convenga, contactanos al 114505-8700`,
      action: "Ver más",
      url: "https://www.tcfautos.com.ar/#/cotizador/?ic=307&utm_source=TASA&utm_medium=Web&utm_campaign=Tasa",
    },
  ];

  const goToUrl = (url) => (window.location = url);

  const renderCards = (style) => (
    <Row
      className="m-0 p-4 cubeContainer justify-content-center"
      style={{
        backgroundImage: "url(images/homeNews/wallFinanciacion.jpg)",
      }}
    >
      <Col
        className={`${style.titleAlignment} mt-1`}
        xs={12}
        style={{ marginBottom: "30%", color: "white" }}
      >
        <span style={{ fontSize: style.titleFontSize, whiteSpace: "pre-line" }}>
          <b>Elegí la forma ideal para{"\n"} financiar tu Toyota</b>
        </span>
      </Col>

      {cardData.map(({ title, description, action, url }) => (
        <Col
          xs={12}
          lg={4}
          style={{ cursor: "pointer" }}
          className="m-0 p-0"
          onClick={() => goToUrl(url)}
        >
          <div className="grow" style={style}>
            <h2
              className="text-white text-left pl-4"
              style={{ marginTop: style.titleMarginTop }}
            >
              {title}
            </h2>
            <p
              className="text-white text-left px-4 pb-4"
              style={{ marginTop: style.descriptionMarginTop }}
            >
              {description}
              <span className="card-link-action" style={{ display: "block" }}>
                {action}
              </span>
            </p>
          </div>
        </Col>
      ))}
    </Row>
  );

  return (
    <>
      <ShowAt breakpoint="largeAndAbove">
        {renderCards(screenSize.DESKTOP)}
      </ShowAt>
      <HideAt breakpoint="largeAndAbove">
        {renderCards(screenSize.MOBILE)}
      </HideAt>
    </>
  );
}
