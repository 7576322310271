import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";

export default function ToyotaApp() {
  return (
    <Row
      className="mx-0 p-0"
      style={{ marginTop: "60px", marginBottom: "40px" }}
    >
      <Col
        xs={12}
        md={{ span: 4, offset: 2 }}
        style={{ whiteSpace: "pre-line" }}
      >
        ¡Nueva App!{"\n"}
        {"\n"}
        <h2>
          <b>NUEVA APP TOYOTA, Conectate con todo lo que te mueve.</b>
        </h2>
        <Row>
          <Col style={{ marginTop: "40px" }}>
            <span>
              Para mejorar la experiencia con Toyota y descubrir todas las
              soluciones de movilidad. Conectate desde donde estés para agendar
              tu turno de servicio, visualizar información clave de tus planes
              de ahorro, descargar los cupones de pago y mucho más.
            </span>
          </Col>
        </Row>
        <Row className="m-0 mt-5 p-0">
          <a href="https://www.toyota.com.ar/mi-toyota/app" target="_self">
            <Button
              variant="outline-dark"
              style={{ borderRadius: "15px" }}
              className="px-5"
            >
              Ver más
            </Button>
          </a>
        </Row>
      </Col>
      <Col
        xs={12}
        md={{ span: 4, offset: 0 }}
        style={{ whiteSpace: "pre-line", marginTop: "1%" }}
        className="text-center"
        id="appRow"
      >
        <a href="https://www.toyota.com.ar/mi-toyota/app">
          <Image
            src="https://store.autoprana.com.ar/img/imgSitio/logos/toyotaApp.png"
            width="250px"
          />
        </a>
        <Row className="m-0 mt-5 p-0">
          <Col className="m-0 p-0" xs={12}>
            <a href="https://play.google.com/store/apps/details?id=com.toyotaargentina.oneapp">
              <Image src="/images/logos/google-play.svg" width="180px" />
            </a>
            <a href="https://apps.apple.com/us/app/toyota-argentina/id1597365457">
              <Image src="/images/logos/app-store.svg" width="165px" />
            </a>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
