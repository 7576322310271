import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter } from "mdbreact";
import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const FooterPage = () => {
  return (
    <MDBFooter
      className="font-small pt-4 mt-0"
      style={{ backgroundColor: "black", color: "white" }}
    >
      <MDBContainer fluid className="text-center text-md-left">
        <MDBRow>
          <MDBCol md="6">
            <h5 className="title">Prana | Web</h5>
            <p>
              Con mas de 50 años en la industria automotriz, damos un nuevo paso
              para acercarnos a nuestros clientes y brindar nuevos servicios.
            </p>
          </MDBCol>
          <MDBCol md="6">
            <ul
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                padding: "0",
              }}
            >
              <li className="list-unstyled">
                <h5 className="title">Sucursales</h5>
              </li>
              <li className="list-unstyled">
                <a href="https://store.autoprana.com.ar/#/sucursales">
                  <span className="text-white">
                    Hace click para ver nuestros puntos de venta.
                  </span>
                </a>
              </li>
              <li className="list-unstyled">
                <table className="mt-2">
                  <tr>
                    <td className="pr-3">
                      <a href="https://www.instagram.com/toyotaprana/">
                        <Image src="/images/logos/instagram.svg" width="30px" />
                      </a>
                    </td>
                    <td className="pr-3 pb-1">
                      <a href="https://www.facebook.com/PranaToyota/">
                        <Image src="/images/logos/facebook.svg" width="13px" />
                      </a>
                    </td>
                    <td className="pr-3">
                      <a href="https://www.youtube.com/c/AutopranaArToyota/videos">
                        <Image src="/images/logos/youtube.svg" width="35px" />
                      </a>
                    </td>
                    <td className="pr-3 pb-1">
                      <a href="https://ar.linkedin.com/company/toyota-prana">
                        <Image src="/images/logos/linkedin.svg" width="25px" />
                      </a>
                    </td>
                    <td className="pr-3 pb-1">
                      <a href="https://www.tiktok.com/@toyotaprana">
                        <Image src="/images/logos/tiktok.svg" width="25px" />
                      </a>
                    </td>
                  </tr>
                </table>
              </li>
            </ul>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          <Link
            to={{
              pathname: "/contacto",
              tipoConsulta: "Libro de quejas",
            }}
          >
            <span style={{ color: "white" }}>Libro de quejas</span>
          </Link>
        </MDBContainer>
      </div>
      <div className="footer-copyright text-center py-3">
        <MDBContainer fluid>
          &copy; {new Date().getFullYear()} Copyright:{" Auto Prana S.A. "}
        </MDBContainer>
      </div>
    </MDBFooter>
  );
};

export default FooterPage;
