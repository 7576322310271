import { useState } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";

export default function useContacto({ tipoConsulta }) {
  console.log(tipoConsulta, "tipoConsulta");

  tipoConsulta =
    tipoConsulta === "Test Drive"
      ? (tipoConsulta = "HOME")
      : tipoConsulta === "Consulta general"
      ? (tipoConsulta = "HOME")
      : tipoConsulta === "Libro de quejas"
      ? (tipoConsulta = "HOME")
      : tipoConsulta === "SERVICIOS"
      ? (tipoConsulta = "SERVICIOS")
      : (tipoConsulta = "HOME");

  let history = useHistory();
  const [contactStatus, setContactStatus] = useState(false);

  const justletterRegex = /^([a-zA-Z ])/;
  const numberRegex = /^[0-9]+$/;
  const phoneRegex =
    /^(?:(?:00)?549?)?0?(?:11|[2368]\d)(?:(?=\d{0,2}15)\d{2})??\d{8}$/;

  const advanceSchema = yup.object().shape({
    nombre: yup.string().required("El nombre y apellido son requeridos"),
    email: yup
      .string()
      .email("Ingrese un correo valido")
      .trim()
      .required("El correo es requerido"),
    telefono: yup
      .string()
      .matches(phoneRegex, { message: "Ingrese un telefono valido" })
      .trim()
      .required("El telefono es requerido"),
    mensaje: yup.string().required("El mensaje es requerido"),
  });

  const onSubmit = async (values, actions) => {
    values = { ...values, area: tipoConsulta };
    console.log(values, "values");

    Swal.fire({
      title: "Procesando envio de mensaje...",
      html: "Espere un momento por favor.",
      timer: contactStatus,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const url = `https://store.autoprana.com.ar/pranaapi/contacto`;
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    console.log(data.status);

    if (data.status === "ok") {
      console.log("enviado correctamente");
      setContactStatus(true);
      Swal.fire({
        icon: "success",
        title: "Su mensaje fue enviado correctamente",
        text: "Gracias por contactarse con nosotros, a la brevedad nos estaremos comunicando con usted.",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
      });
      history.push("/");
    } else {
      console.log("error");
      setContactStatus(true);
      Swal.fire({
        icon: "error",
        title: "Error al enviar el mensaje",
        html: "verifique los datos ingresados",
        //text: "verifique los datos ingresados y si cuenta con autorizacion de su tarjeta para realizar la compra en nuestro store. <br> Visa autorizaciones 4379-3400 <br> Mastercard autorizaciones 4379-3401 <br> Amex autorizaciones 4379-3402 <br> Naranja autorizaciones 4379-3403 <br>",
        showConfirmButton: true,
        confirmButtonText: "Aceptar",
      });
    }

    //actions.resetForm();
  };

  return {
    advanceSchema,
    onSubmit,
    numberRegex,
    justletterRegex,
  };
}
