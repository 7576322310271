import React, { useContext } from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { uiContext } from "../../contexts/UiContextProvider";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ShowAt } from "react-with-breakpoints";

import Image from "react-bootstrap/Image";
import { Link } from "react-router-dom";

export default function NavigationBar() {
  const uiManager = useContext(uiContext);

  return (
    <Navbar
      bg="white"
      variant="light"
      style={{ borderBottom: "1px solid lightgray" }}
    >
      <Link to="/">
        <Navbar.Brand>
          <Row className="m-0 p-0">
            <Col className="m-0 p-0">
              <Image
                width="30px"
                src="/images/logos/marca.png"
                className="pb-1"
              />
              <span
                className="pl-2"
                style={{ fontSize: "20px", fontFamily: "ToyotaType-Bold" }}
              >
                <b>
                  | <i>PRANA</i>
                </b>
              </span>
            </Col>
          </Row>
        </Navbar.Brand>
      </Link>

      <Nav className="mr-auto"></Nav>
      <ShowAt breakpoint="largeAndAbove">
        <Nav className="mr-auto">
          <Link
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            exact
            to="/modelos"
          >
            <b className="changeColor">Modelos</b>
          </Link>
          <a
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            href="https://store.autoprana.com.ar/vtf/"
          >
            <b className="changeColor">Reserva tu 0km</b>
          </a>
          <a
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            href="https://store.autoprana.com.ar/#/usados"
          >
            <b className="changeColor">Usados</b>
          </a>
          {/* <a
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            href="https://store.autoprana.com.ar/cotizador/"
          >
            <b className="changeColor">Cotizá tu usado</b>
          </a> */}
          <Link
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            exact
            to="/posventa"
          >
            <b className="changeColor">Servicios y Accesorios</b>
          </Link>
          <Link
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            exact
            to="/financiacion"
          >
            <b className="changeColor">Financiación</b>
          </Link>
          <a
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            href="https://store.autoprana.com.ar/#/kinto"
          >
            <b className="changeColor">Kinto</b>
          </a>
          <a
            className="px-4"
            style={{ color: "black", fontSize: "14px" }}
            href="https://www.clubtoyota.com.ar/"
          >
            <b className="changeColor">Club Toyota</b>
          </a>
        </Nav>
      </ShowAt>
      <Nav>
        <Navbar.Brand
          style={{ fontSize: "20px", marginRight: "5px", cursor: "pointer" }}
          onClick={uiManager.sideBar.show}
        >
          Menú
        </Navbar.Brand>
        <Button
          className="d-block d-md-none without-focus-border"
          variant="transparent"
          onClick={uiManager.sideBar.show}
          style={{ paddingLeft: "0px", paddingTop: "10px" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "black" }} />
        </Button>
        <Button
          className="d-none d-md-block without-focus-border"
          variant="transparent"
          onClick={uiManager.sideBar.show}
          style={{ paddingTop: "9px" }}
        >
          <FontAwesomeIcon icon="bars" style={{ color: "black" }} />
        </Button>
        <ShowAt breakpoint="mediumAndAbove"></ShowAt>
      </Nav>
    </Navbar>
  );
}
